import { useEffect, useState } from "react";
import RolesList from "./Components/RolesList";
import Addusers from "./Components/AddUsers";
import { BASE_URL_TWO, rolesTableData } from "Constant/Constant";
import axios from "axios";
import TableLoader from "components/TableLoader/TableLoader";
import { CreateRoleLists } from "./CreateRolesInterfaces";

const CreateRoles = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<CreateRoleLists[]>([]);


  const fetchApiData = () => {
    axios.get(`${BASE_URL_TWO}getUsersLists`).then((res) => {
      setLoading(false);
      setUsers(res.data.data);
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    })
  }

  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <div className="py-5 bg-white m-3 rounded-md dark:!bg-navy-900">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              onClick={() => setOpen(!open)}
            >
              Add user
            </button>
          </div>
        </div>
        {loading ? <TableLoader tableLoaderData={rolesTableData} /> :
            <RolesList users={users} fetchApiData={fetchApiData} />
        }
      </div>
      <Addusers open={open} setOpen={setOpen} fetchApiData={fetchApiData} />
    </div>
  );
};

export default CreateRoles;
