import Card from "components/card";

const NFLLoader = () => {

    return (
        <Card extra={"w-full h-full sm:overflow-auto px-6 mt-5"}>

            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
                <table className="w-full">
                    <thead>
                        <tr className="!border-px !border-gray-400">
                            <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                                <div className="items-center justify-between text-xs text-gray-200">
                                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                                        Card number
                                    </p>
                                </div>
                            </th>
                            <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                                <div className="items-center justify-between text-xs text-gray-200">
                                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                                        Balance

                                    </p>
                                </div>
                            </th>
                            <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                                <div className="items-center justify-between text-xs text-gray-200">
                                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                                        Created at

                                    </p>
                                </div>
                            </th>
                            <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                                <div className="items-center justify-between text-xs text-gray-200">
                                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                                        Active

                                    </p>
                                </div>
                            </th>
                            <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                                <div className="items-center justify-between text-xs text-gray-200">
                                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                                        Phone

                                    </p>
                                </div>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-44 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-32 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                            <td className="min-w-[150px] border-white/0 py-3  pr-4">
                                <div className="h-4 w-20 rounded bg-gray-400"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default NFLLoader;


//  product details
//   dashboard 
//   users 
//   these are used components