import Card from "components/card";
let LoaderColumns = {
  one:"",
  two:"",
  three:"",
  four:"",
  five:""
}

const ProductDetailsLoader = (props: {path:String}) => {

  const { path } = props;

  if(path === "users"){
    LoaderColumns = {
      one:"Name",
      two:"Phone Number",
      three:"Balance",
      four:"Card Number",
      five:"Created at"
    }
  }else if(path === "dashboard"){
    LoaderColumns = {
      one:"NAME",
      two:"USER SINCE",
      three:"CONTACT",
      four:"AMOUNT SPEND",
      five:""
    }
  }else {
    LoaderColumns = {
      one:"MACHINE",
      two:"LOCATION",
      three:"PRODUCT PRICE",
      four:"EDIT",
      five:""
    }
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex animate-pulse items-center justify-between pt-4">
        <div className="h-4 w-20 rounded bg-gray-400"></div>

        <div className="h-4 w-10 rounded bg-gray-400"></div>
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            <tr className="!border-px !border-gray-400">
              <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                <div className="items-center justify-between text-xs text-gray-200">
                  <p className="text-sm font-bold text-gray-600 dark:text-white">
                    {LoaderColumns.one}
                  </p>
                </div>
              </th>
              <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                <div className="items-center justify-between text-xs text-gray-200">
                  <p className="text-sm font-bold text-gray-600 dark:text-white">
                  {LoaderColumns.two}

                  </p>
                </div>
              </th>
              <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                <div className="items-center justify-between text-xs text-gray-200">
                  <p className="text-sm font-bold text-gray-600 dark:text-white">
                  {LoaderColumns.three}

                  </p>
                </div>
              </th>
              <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                <div className="items-center justify-between text-xs text-gray-200">
                  <p className="text-sm font-bold text-gray-600 dark:text-white">
                  {LoaderColumns.four}

                  </p>
                </div>
              </th>
              {path === "dashboard" ? (
                <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                      TOTAL TRANSACTIONS
                    </p>
                  </div>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            <tr className="animate-pulse">
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-44 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-20 rounded bg-gray-400"></div>
              </td>
            </tr>
            <tr className="animate-pulse">
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-44 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-20 rounded bg-gray-400"></div>
              </td>
            </tr>
            <tr className="animate-pulse">
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-44 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-20 rounded bg-gray-400"></div>
              </td>
            </tr>
            <tr className="animate-pulse">
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-44 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-32 rounded bg-gray-400"></div>
              </td>
              <td className="min-w-[150px] border-white/0 py-3  pr-4">
                <div className="h-4 w-20 rounded bg-gray-400"></div>
              </td>
            </tr>
            {path === "dashboard" ? (
              <tr className="animate-pulse">
                <td className="min-w-[150px] border-white/0 py-3  pr-4">
                  <div className="h-4 w-32 rounded bg-gray-400"></div>
                </td>
                <td className="min-w-[150px] border-white/0 py-3  pr-4">
                  <div className="h-4 w-44 rounded bg-gray-400"></div>
                </td>
                <td className="min-w-[150px] border-white/0 py-3  pr-4">
                  <div className="h-4 w-32 rounded bg-gray-400"></div>
                </td>
                <td className="min-w-[150px] border-white/0 py-3  pr-4">
                  <div className="h-4 w-20 rounded bg-gray-400"></div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ProductDetailsLoader;


//  product details
//   dashboard 
//   users 
//   these are used components