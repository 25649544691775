import { allMachines } from "Constant/Constant";
import moment from 'moment-timezone';
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

function PointsShareTable(props: { tableData: any }) {
  const { tableData } = props;

  const [machineNumber, setMachineNumber] = useState("")

  let tableDataFilter = machineNumber && tableData.filter((e: any) => e.id.includes(machineNumber))

  // const tableDataCsv = tableData.map((e: any) => {
  //   return { ...e, brands: e.brands.map((f: any) => f.name).flat().toString() }
  // })

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:!text-gray-500">
            Points Share
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the machines in your account including their machine
            id, locations, type and brands.
          </p>
        </div>
        <div>
          {/* <CSVLink data={tableDataCsv} headers={allMachines} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink> */}
        </div>
      </div>
      <div className="mt-3">
        <input
          type="text"
          name="text"
          id="text"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search by machine number"
          onChange={(e: any) => setMachineNumber(e.target.value)}
        />
      </div>
      <div className="mt-3 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-gray-900 ">
                  <tr>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Transfer Names
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Transafer Phone
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Transfer Date
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {machineNumber ?
                    tableDataFilter && tableDataFilter.map((machine: any, key: any) => (
                      <tr key={machine.id}>
                        <td className="text-gray-900 dark:!text-gray-500 sm:pl-0 text-center whitespace-nowrap text-sm font-medium">
                          {`${machine.alternate_name} => ${machine.user_name}`}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {`${machine.msisdn} => ${machine.alternate_msisdn}`}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.amount}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {moment(machine.created_at).tz('Etc/GMT-0').format("llll")}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.status}
                        </td>
                      </tr>
                    )) :
                    tableData.map((machine: any, key: number) => (
                      <tr key={machine.id}>
                        <td className="text-gray-900 dark:!text-gray-500 sm:pl-0 text-center whitespace-nowrap text-sm font-medium">
                          {`${machine.alternate_name} => ${machine.user_name}`}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {`${machine.msisdn} => ${machine.alternate_msisdn}`}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.amount}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {moment(machine.created_at).tz('Etc/GMT-0').format("llll")}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.status}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PointsShareTable;
