import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductDetailsLoader from "components/Loader/productDetailsLoader";
import LocationsTable from "./components/LocationsTable";

import {
  locationsByCompanySelector,
  getLocationByCompany,
} from "../../../Redux/Features/Dashboard/GetLocationsByCompany";
import { locationsTableData } from "Constant/Constant";
import TableLoader from "components/TableLoader/TableLoader";

const Tables = () => {
  const dispatch = useDispatch<any>();

  const {
    locationsByCompanyData,
    locationsByCompanyLoader,
    locationsByCompanyErrors,
  } = useSelector(locationsByCompanySelector);

  useEffect(() => {
    dispatch(getLocationByCompany());
  }, [dispatch]);

  return (
    <div className="py-5 bg-white m-3 rounded-md dark:!bg-navy-900">
      {!locationsByCompanyLoader ? (
        <LocationsTable tableData={locationsByCompanyData} />
      ) : (
        <TableLoader tableLoaderData={locationsTableData}/>
      )}
    </div>
  );
};

export default Tables;
