import { useState } from "react";
import Machine from "./Tabs/Machine";
import SalesAndUsage from "./Tabs/SalesAndUsage";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function LocationDetails() {
  const [currentTab, setCurrentTab] = useState("Machine");

  return (
    <div className="py-5">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          <span
            className={classNames(
              currentTab === "Machine"
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              "group relative min-w-0 flex-1 cursor-pointer overflow-hidden rounded-l-lg dark:!bg-gray-900 dark:!text-gray-500 bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            onClick={() => setCurrentTab("Machine")}
          >
            <span>Machines</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentTab === "Machine" ? "bg-indigo-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </span>

          <span
            className={classNames(
              currentTab === "SalesAndUsage"
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              "group relative min-w-0 flex-1 cursor-pointer overflow-hidden dark:!bg-gray-900 dark:!text-gray-500 rounded-r-lg bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            onClick={() => setCurrentTab("SalesAndUsage")}
          >
            <span>Sales and usage</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentTab === "SalesAndUsage"
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </span>
        </nav>
      </div>
      {currentTab === "Machine" ? <Machine /> : <SalesAndUsage />}
    </div>
  );
}

export default LocationDetails;
