import { useEffect, useState } from "react";
import axios from "axios";
import TableLoader from "components/TableLoader/TableLoader";
import { BASE_URL, BASE_URL_TWO, decryptObject, stockManagment } from "Constant/Constant";

export default function CompanyStocks() {
  const [loading, setLoading] = useState(true);
  const [fillData, setFillData] = useState([]);
  const [stockout, setStockOut] = useState([]);
  const [brandName, setBrandName] = useState([]);
  const [brand_id, setBrand_id] = useState([]);

  //localStorage
  const authData = localStorage.getItem("auth");
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  useEffect(() => {
    getStocks();
  }, []);

  const getStocks = () => {
    axios.get(`${BASE_URL_TWO}butterflyStocks/${localStorageData.user.machines[0].machine_code}`)
      .then((response) => {
        const { brandfillingData, stockoutData, brand_name, brand_id } = response.data;
        setFillData(brandfillingData);
        setStockOut(stockoutData);
        setBrandName(brand_name);
        setBrand_id(brand_id);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full">
      <h1 className="py-5 text-gray-900 dark:!text-gray-500 text-4xl font-extrabold leading-7">Stocks</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {
                loading ?
                  <TableLoader tableLoaderData={stockManagment} /> :

                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Brand Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Brand id
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Stock used
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Stock filled
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {loading ? <h1>Loading..</h1> : fillData.map((data, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-black dark:!text-gray-500">{brandName[index]}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-black dark:!text-gray-500">{brand_id[index]}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-black dark:!text-gray-500">{stockout[index]}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-black dark:!text-gray-500">{data}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
