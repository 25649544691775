import { useEffect, useState } from "react";
import ProductDetailsLoader from "components/Loader/productDetailsLoader";
import axios from "axios";
import { BASE_URL_TWO, addCommasToNumber } from "Constant/Constant";
import moment from "moment";

const TransactionHistory = () => {

  const [transactionHistoryLoader, setTransactionHistoryLoader] = useState(false);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);

  useEffect(() => {
    setTransactionHistoryLoader(true);
    const apiUrl = `${BASE_URL_TWO}webGetTopupBalance`;

    axios.get(apiUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response: any) => {
        setTransactionHistoryData(response.data.topupBalance);
        setTransactionHistoryLoader(false);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        setTransactionHistoryLoader(false);
      });
  }, []);

  const reversedMappedArray = transactionHistoryData.reverse().map((obj: any, index: any) => {
    return { ...obj, value: obj.value + ' (modified)' };
  });

  const calculateTime = (time: number) => {
    const userCreatedAt = time
    const userCreatedAtGMT = moment(userCreatedAt).tz('Etc/GMT-0');
    const userCreatedAtGMTplus5 = userCreatedAtGMT.clone().add(5, 'hours');
    const formattedDate = userCreatedAtGMTplus5.format('llll');
    return formattedDate;
  }

  return (
    <>
      <div className="mt-5 flex"></div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
              {transactionHistoryLoader ? (
                <ProductDetailsLoader path={"dashboard"} />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50 dark:!bg-gray-900">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Phone number/Rfid
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Total Balance
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Previous Balance
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Balacne Added
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Created at
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Account Holder
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Payment Purpose
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white dark:!bg-gray-900">
                    {transactionHistoryData && transactionHistoryData.length === 0 ?
                      <tr>
                        <td
                          colSpan={6}
                          className="w-full py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500"
                        >
                          No data found
                        </td>
                      </tr>
                      : reversedMappedArray && reversedMappedArray.reverse().map((user) => (
                        <tr key={user.transaction_number}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {user.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {user.msisdn}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6 font-semibold">
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              {addCommasToNumber(parseInt(user.total_balance))}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {addCommasToNumber(parseInt(user.previous_balance)) || 0}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {addCommasToNumber(parseInt(user.balance_added))}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {calculateTime(parseInt(user.created_at))}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {user.user_email=== "undefined" ? "" : user.user_email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {user.description === "undefined" ? "" : user.description}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
