import React from "react";
import pageNotFound from "../../assets/img/not-found.jpg";

const PageNotFound = () => {
  return (
    <div className="flex justify-center items-center">
      <img src={pageNotFound} alt="page not found" style={{height:"100vh"}}/>
    </div>
  );
};

export default PageNotFound;
