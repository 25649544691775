import { BASE_URL_TWO, machineTableData } from "Constant/Constant";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import AddUser from "./Components/AddUser";
import DeleteConfirmModal from "./Components/DeleteConfirmModal";
import { BiShow, BiHide } from "react-icons/bi";
import TableLoader from "components/TableLoader/TableLoader";

export default function MeritPackaging() {
    //localStorage
    const authData = localStorage.getItem("auth");
    const localStorageDataDecrypted = JSON.parse(authData);

    const [nflUsers, setNflUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [deleteId, setDeleteId] = useState(NaN);
    const [showPasswords, setShowPasswords] = useState(nflUsers.map(() => false));
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUsers();
    }, []);

    const handleDelete = (id: number) => {
        setDeleteId(id);
        setDeleteConfirmationModal(true);
    }

    const getUsers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL_TWO}getMeritPackaingUsers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorageDataDecrypted.encryptedUser
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setNflUsers(data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-5">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all the users in your account including their name, title, email and role.
                    </p>
                </div>

                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        onClick={() => setOpen(true)}
                    >
                        Add user
                    </button>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            {
                                loading ? 
                                <TableLoader tableLoaderData={machineTableData} />:
                                <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            SNO
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Employee id
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Pin
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Balance
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Created at
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Machine
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {
                                    nflUsers.map((person, i) => (
                                        <tr key={person.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {i + 1}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {person.mobile_number}
                                            </td>
                                            <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                                            <div className="text-gray-900 flex items-center dark:!text-gray-500">
                                                {showPasswords[i] ? (
                                                    <span>{person.pin}</span>
                                                ) : (
                                                    <span>********</span>
                                                )}
                                                {showPasswords[i] ? (
                                                    <BiHide
                                                        className="ml-3 cursor-pointer"
                                                        onClick={() => {
                                                            const newShowPasswords = [...showPasswords];
                                                            newShowPasswords[i] = !newShowPasswords[i];
                                                            setShowPasswords(newShowPasswords);
                                                        }}
                                                    />
                                                ) : (
                                                    <BiShow
                                                        className="ml-3 cursor-pointer"
                                                        onClick={() => {
                                                            const newShowPasswords = [...showPasswords];
                                                            newShowPasswords[i] = !newShowPasswords[i];
                                                            setShowPasswords(newShowPasswords);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{person.balance}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{moment(person.created_at).tz('Etc/GMT-0').format("llll")}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{person.machine_code}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                <button
                                                    className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                                    onClick={() => handleDelete(person.id)}
                                                >Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

            <AddUser open={open} setOpen={setOpen} getUsers={getUsers}/>
            <DeleteConfirmModal open={deleteConfirmationModal} setOpen={setDeleteConfirmationModal} deleteId={deleteId} getUsers={getUsers}/>
        </div>
    )
}

