import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { BASE_URL } from "Constant/Constant";

function ProductModal(props: {
  open: any;
  setOpen: any;
  productDetailsData: any;
}) {
  const { open, setOpen, productDetailsData } = props;
  const { register, handleSubmit, reset } = useForm();

  const [updateBrandsLoader, setUpdateBrandsLoader] = useState(false);
  const [updateBrandsData, setUpdateBrandsData] = useState(null);

  const onSubmit = (data: any) => {
    setUpdateBrandsLoader(true);
    const data2 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "updateBrandPricing",
          data: {
            brand_name: productDetailsData.brandName.label,
            machine_id: data.machineId,
            price: data.productPrice,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}brands`, data2)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then((data) => {
      setUpdateBrandsLoader(false);
      setUpdateBrandsData(data);
    });

    setUpdateBrandsLoader(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="py-5">
                <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Product Details
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full">
                        <label
                          htmlFor="machineId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine ID
                        </label>
                        <input
                          type="text"
                          name="machineId"
                          id="machineId"
                          defaultValue={productDetailsData.machineId}
                          {...register("machineId")}
                          className="mt-1 block w-full rounded-md border border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="productPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Product Price
                        </label>
                        <input
                          type="text"
                          name="productPrice"
                          id="productPrice"
                          defaultValue={productDetailsData.price}
                          {...register("productPrice")}
                          className="mt-1 block w-full rounded-md border border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="border-transparent inline-flex w-full justify-center rounded-md border bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ProductModal;
