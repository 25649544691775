import Card from "components/card";

const DashboardCardLoader = () => {
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-gray-500 p-3 dark:bg-navy-700">
          <span className="flex items-center text-gray-500 dark:text-white"></span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-full flex-col justify-center ">
        <div className="animate-pulse rounded-lg bg-white dark:bg-navy-700 p-4">
          <div className="space-y-2">
            <div className="h-4 w-full rounded bg-gray-400"></div>
            <div className="h-4 w-1/2 rounded bg-gray-400"></div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DashboardCardLoader;
