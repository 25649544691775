import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="animate-pulse rounded-lg bg-white p-4 dark:bg-navy-700">
      <div className="space-y-2">
        <div className="h-4 w-full rounded bg-gray-400"></div>
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="h-4 w-1/2 rounded bg-gray-400"></div>
        <div className="h-4 w-full rounded bg-gray-400"></div>
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="h-4 w-1/2 rounded bg-gray-400"></div>
        <div className="h-4 w-full rounded bg-gray-400"></div>
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="h-4 w-1/2 rounded bg-gray-400"></div>
        <div className="h-4 w-full rounded bg-gray-400"></div>
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="h-4 w-1/2 rounded bg-gray-400"></div>
        <div className="h-4 w-full rounded bg-gray-400"></div>
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="h-4 w-1/2 rounded bg-gray-400"></div>
        <div className="h-4 w-full rounded bg-gray-400"></div>
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="h-4 w-1/2 rounded bg-gray-400"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
