import { useEffect, useState } from "react";
import {
  machinesByCompanySelector,
  getMachinesByCompany
} from "../../../../Redux/Features/Dashboard/GetMachinesByCompany";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TableLoader from "components/TableLoader/TableLoader";
import { COMPANY, DONT_ACCESS, decryptObject, machineStockManagment, stockManagment } from "Constant/Constant";
import UpdatePriceModal from "../Components/UpdatePriceModal";

function StockManagment() {
  const dispatch = useDispatch<any>();
  const [open, setOpen] = useState(false);

  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  let { id } = useParams();

  const { machinesByCompanyData, machinesByCompanyLoader } = useSelector(
    machinesByCompanySelector
  );

  useEffect(() => {
    dispatch(getMachinesByCompany(id));
  }, [dispatch]);

  const machineBrands = machinesByCompanyData;

  const handleUpdatePrice = () => {
    if (localStorageData.user.user_role === "ops") {
      alert(DONT_ACCESS)
    } else {
      setOpen(true)
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="py-10 text-gray-900 dark:!text-gray-500 text-4xl font-extrabold leading-7">
        
          {localStorageData.user.user_role === COMPANY ? "Price" : "Update price"}
        </h1>
        {/* <button
          className="block w-32 rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          onClick={handleUpdatePrice}
        >
          Update price
        </button> */}
        {/* <CSVLink data={machineBrands.brands} headers={machineStockManagment} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
          Export as csv
        </CSVLink> */}
      </div>
      <div className="">
        <div className=" flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className={`${machinesByCompanyLoader ? "" : "ring-black shadow ring-1 ring-opacity-5 overflow-hidden sm:rounded-lg"}`}>
                {
                  machinesByCompanyLoader ?
                    <TableLoader tableLoaderData={stockManagment} /> :
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50 dark:!bg-gray-900">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:!text-white"
                          >
                            Brand name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:!text-white"
                          >
                            Price{" "}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:!text-white"
                          >
                            Brand id
                          </th>
                          {/* <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:!text-white"
                          >
                            Bar code{" "}
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {machineBrands && machineBrands ?
                          machineBrands.map((person: any, index: any) => (
                            <tr key={person.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                                {index + 1}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                {person.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                {person.price || person.litre_price}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                {person.id}
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                {person.row_num}
                              </td> */}
                            </tr>
                          )) : <>
                          </>}
                      </tbody>
                    </table>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        // machineBrands &&
        <UpdatePriceModal open={open} setOpen={setOpen}
        // machineBrands={machineBrands}
        />
      }

    </div>

  );
}

export default StockManagment;
