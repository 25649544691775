import React, { useState, useEffect } from "react";
import { BASE_URL, decryptObject, productTableData } from "Constant/Constant";
import ProductTable from "./components/ProductTable";
import TableLoader from "components/TableLoader/TableLoader";

const ProfileOverview = () => {
  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const [brandsLoader, setBrandsLoader] = useState(false);
  const [brandsData, setBrandsData] = useState([]);

  const [brandsTableLoader, setBrandsTableLoader] = useState(false);

  useEffect(() => {
    //list
    setBrandsLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getBrandNamesByCompany",
          data: {
            company_code: localStorageData.user.company_code,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}brands`, data)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then((data) => {
      setBrandsLoader(false);
      setBrandsData(data?.response?.data?.brands);
    });
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      {!brandsLoader && !brandsTableLoader ? (
        <ProductTable brandsData={brandsData} />
      ) : (
        <TableLoader tableLoaderData={productTableData} />
      )}
    </div>
  );
};

export default ProfileOverview;
