import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../AppContainer";

export interface darkModeState {
  darkMode: boolean;
}

const initialState: darkModeState = {
  darkMode: false,
};

const darkModeToggle = createSlice({
  name: "DARK MODE",
  initialState,
  reducers: {
    setDarkMode: (state, { payload }: PayloadAction<boolean>) => {
      state.darkMode = payload;
    },
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
  },
});

export const { setDarkMode, toggleDarkMode } = darkModeToggle.actions;

export const getBottleDispensedByCompany = (): AppThunk => {
  return async (dispatch) => {
    dispatch(toggleDarkMode());
  };
};

export default darkModeToggle.reducer;

export const darkModeSelector = (state: {
  darkModeStore: darkModeState;
}) => state.darkModeStore;
