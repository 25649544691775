import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL, decryptObject } from "Constant/Constant";

interface YourObjectType {
  plastic_saved?: number | string;
}

export interface dashboardState {
  data: YourObjectType;
  loading: boolean;
  errors: string;
}

const initialState: dashboardState = {
  data: {},
  loading: false,
  errors: "",
};

const dashboardSlice = createSlice({
  name: "PLASTIC SAVE BY COMPANY",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },

    setPlasticSavedByCompany: (state, { payload }: PayloadAction<any>) => {
      state.data = payload;
    },
  },
});

// AppThunk sets the type definitions for the dispatch method
export const getPlasticSavedByCompany = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    //localStorage
    const authData = localStorage.getItem("auth");
    // const localStorageData = JSON.parse(authData);
    const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

    const plasticSaved = {
      request: {
        method: "getPlasticSavedByCompany",
        data: {
          company_code: localStorageData.user.company_code
        },
      },
    };

    try {
      const baseURL: string = `${BASE_URL}user/transaction`;

      const res = await axios({
        method: "post",
        url: baseURL,
        data: plasticSaved,
      });

      dispatch(setLoading(false));
      dispatch(setPlasticSavedByCompany(res.data.response.data));
    } catch (error: any) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const { setLoading, setErrors, setPlasticSavedByCompany } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;

export const dashboardSelector = (state: { dashboardStore: dashboardState }) =>
  state.dashboardStore;
