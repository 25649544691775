/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
 
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import PageNotFound from "components/PageNotFound/PageNotFound";
import PrivacyPolicy from "views/auth/PrivacyPolicy";
import CompanyInfo from "views/auth/CompanyInfo";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      // Redirect to '/admin/*' if 'auth' value exists in localStorage
      navigate("/admin/home");
    }
  }, []);

  const auth = localStorage.getItem("auth");

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={<Navigate to={auth ? "admin" : "auth"} replace />}
        />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="auth/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="auth/company-info" element={<CompanyInfo />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default App;
