import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL, COMPANY, decryptObject } from "Constant/Constant";

export interface bottleDispensedState {
  bottleDispensedByCompanyData: [];
  BottleDispensedByCompanyLoader: boolean;
  bottleDispensedByCompanyErrors: string;
}

const initialState: bottleDispensedState = {
  bottleDispensedByCompanyData: [],
  BottleDispensedByCompanyLoader: false,
  bottleDispensedByCompanyErrors: "",
};

const getBottleDetailsByCompany = createSlice({
  name: "BOTTLE DISPENSED BY COMPANY",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.BottleDispensedByCompanyLoader = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.bottleDispensedByCompanyErrors = payload;
    },

    setBottleDispensedByCompany: (state, { payload }: PayloadAction<any>) => {
      state.bottleDispensedByCompanyData = payload;
    },
  },
});

// AppThunk sets the type definitions for the dispatch method
export const getBottleDispensedByCompany = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    //localStorage
    const authData = localStorage.getItem("auth");
    // const localStorageData = JSON.parse(authData);
    const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

    const locationsByCompany = {
      request: {
        method: "getBottleDetailsByCompany",
        data: {
          company_code: localStorageData.user.company_code,
        },
      },
    };

    if (localStorageData.user.user_role === COMPANY) {
      return;
    } else {
      try {
        const baseURL: string = `${BASE_URL}details/bottle`;

        const res = await axios({
          method: "post",
          url: baseURL,
          data: locationsByCompany,
        });

        dispatch(setLoading(false));
        dispatch(
          setBottleDispensedByCompany(res?.data?.response?.data?.bottle_details)
        );
      } catch (error: any) {
        dispatch(setErrors(error));
        dispatch(setLoading(false));
      }
    }
  };
};

export const { setLoading, setErrors, setBottleDispensedByCompany } =
  getBottleDetailsByCompany.actions;

export default getBottleDetailsByCompany.reducer;

export const bottleDispensedByCompanySelector = (state: {
  bottleDispensedByCompanyStore: bottleDispensedState;
}) => state.bottleDispensedByCompanyStore;
