import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL, BASE_URL_TWO } from "Constant/Constant";

export interface dashboardState {
  machinesByCompanyData: any;
  machinesByCompanyLoader: boolean;
  machineByCompanyErrors: string;
}

const initialState: dashboardState = {
  machinesByCompanyData: [],
  machinesByCompanyLoader: false,
  machineByCompanyErrors: "",
};

const machinesByCompany = createSlice({
  name: "MACHINES BY COMPANY",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.machinesByCompanyLoader = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.machineByCompanyErrors = payload;
    },

    setMachinesByCompany: (state, { payload }: PayloadAction<any>) => {
      state.machinesByCompanyData = payload;
    },
  },
});

export const getMachinesByCompany = (machineId: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const baseURL: string = `${BASE_URL_TWO}stockManagment/${machineId}`;

      const res = await axios({
        method: "get",
        url: baseURL,
      });

      dispatch(setLoading(false));
      dispatch(setMachinesByCompany(res?.data.data));
    } catch (error: any) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const {
  setLoading,
  setErrors,
  setMachinesByCompany,
} = machinesByCompany.actions;

export default machinesByCompany.reducer;

export const machinesByCompanySelector = (state: {
  machinesByCompanyStore: dashboardState;
}) => state.machinesByCompanyStore;
