import React, { useEffect, useState } from 'react';
import NationalFoodsTenStackTable from './NFL/NationalFoodsTenStackTable';
import { BASE_URL_TWO } from 'Constant/Constant';

interface Tab {
    name: string;
    current: boolean;
    id: number;
    machine_code: String;
}

const TopupNFL: React.FC = () => {
    //localStorage
    const authData = localStorage.getItem("auth");
    const localStorageDataDecrypted = JSON.parse(authData);

    const [tabs, setTabs] = useState<Tab[]>([
        { name: 'National foods', current: true, id: 1, machine_code: "3113" },
    ]);

    const [nflLoader, setNflLoader] = useState(false);
    const [nflUsersLists, setNflUsersLists] = useState([]);

    const getNFLList = () => {
        setNflLoader(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': localStorageDataDecrypted.encryptedUser, // Replace with actual token if required
        });

        fetch(`${BASE_URL_TWO}topupList/3113`, { headers })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                setNflLoader(false);
                setNflUsersLists(data.corporateClients);
            })
            .catch((error) => {
                setNflLoader(false);
                console.log("error", error);
            });
    }


    useEffect(() => {
        getNFLList();
    }, []);


    const handleTabs = (tab: Tab) => {
        setTabs(
            tabs.map((t) => ({
                ...t,
                current: t.id === tab.id,
            }))
        );
    };

    return (
        <div>
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <span
                                    key={tab.name}
                                    className={`cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${tab.current
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                        }`}
                                    onClick={() => handleTabs(tab)}
                                >
                                    {tab.name}
                                </span>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>


            <div>
                {tabs.find((tab) => tab.current)?.name === "National foods" ?
                    <NationalFoodsTenStackTable
                        nflUserLoader={nflLoader}
                        nflUserLists={nflUsersLists}
                        getNflList={getNFLList} />
                    :
                    null
                }
            </div>
        </div>
    );
};

export default TopupNFL;
