import React, { useState, useEffect, useMemo } from "react";
import { BASE_URL, addCommasToNumber, decryptObject, usersListHeader } from "Constant/Constant";
import Pagination from "./components/Pagination/Pagination";
import Modal from "./components/AddUserModal";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "./components/DeleteModal";
import { AiOutlineUserAdd } from "react-icons/ai";
import ProductDetailsLoader from "components/Loader/productDetailsLoader";
import LibraryPagination from "../../../components/Pagination/LibraryPagination";
import RfidModal from "./components/rfidModal";
import { CSVLink } from "react-csv";
import moment from 'moment-timezone';

const Users = () => {
  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  //get User data
  const [getUsersLoader, setGetUsersLoader] = useState(false);
  const [getUsersData, setGetUsersData] = useState([]);

  //Pagination
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return getUsersData.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, getUsersData]);

  //delete user
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUserLoader, setDeleteUserLoader] = useState(false);
  const [deleteUserPhoneNumber, setDeleteUserPhoneNumber] = useState("");

  //modal add user
  const [open, setOpen] = useState(false);

  //modal rfid user
  const [rfidOpen, setRfidOpen] = useState(false);

  //search
  const [search, setSearch] = useState("");

  useEffect(() => {
    getUsersList();
  }, []);

  //
  const getUsersList = () => {
    setGetUsersLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },

      body: JSON.stringify({
        request: {
          method: "getDavaamCardUsers",
        },
      }),
    };
    const result = fetch(`${BASE_URL}machines/user`, data)
      .then((response: any) => response.json(response))
      .catch((error) => {
        console.log("error", error);
      });
    result.then((data) => {
      setGetUsersData(data.response.data.davaam_users);
      setGetUsersLoader(false);
    });
  };

  const filterUserData = getUsersData.filter((e: any) => {
    return e.mobile_number.includes(`${search}`);
  });

  return (
    <>
      <div className="px-4 py-6 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900 dark:!text-white">
              Users
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:!text-white">
              A list of all the users in your account including their Phone
              numbers and balance
            </p>
          </div>

          {
            localStorageData.user.user_role === "ops" ?
              null :
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex">
                <button
                  type="button"
                  className="flex items-center justify-between rounded-md bg-teal-500 px-6 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => setOpen(true)}
                >
                  <AiOutlineUserAdd className="mr-1" />
                  Add user
                </button>
                <button
                  type="button"
                  className="flex mx-2 items-center justify-between rounded-md bg-teal-500 px-6 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => setRfidOpen(true)}
                >
                  <AiOutlineUserAdd className="mr-1" />
                  Add rfid
                </button>
              </div>
          }

        </div>
        <div>
          <input
            type="number"
            name="search"
            id="search"
            className="bg-transparent border-1 mt-3 block  w-full flex-1 rounded-md border-0 py-1.5 pl-1 text-gray-900 ring-teal-600 placeholder:text-gray-400 focus:ring-0 dark:!bg-gray-900 dark:!text-white sm:text-sm sm:leading-6"
            placeholder="Search by Phone number"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="flex justify-end items-center my-3">
          <CSVLink data={filterUserData} headers={usersListHeader} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
        </div>
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
                {getUsersLoader ? (
                  <ProductDetailsLoader path={"users"} />
                ) : (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 dark:!bg-gray-900 ">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                        >
                          Phone Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                        >
                          Balance
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                        >
                          Card Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                        >
                          Created at
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 dark:!text-white sm:pr-6"
                        >
                          <span className="sr-only">Update balance</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white dark:!bg-gray-900">
                      {search.length > 0
                        ? filterUserData.map((person, i) => (
                          <tr key={person.id}>
                            <td className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-white">
                              {person.mobile_number}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-white">
                              {person.balance}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-white">
                              {person.card_number}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-white">
                              {moment(person.created_at_sql).tz('Etc/GMT-0').format("llll")}
                            </td>
                            {
                              localStorageData.user.user_role === "ops" ? <td></td> :
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <button
                                    className="rounded-lg bg-teal-600 p-2 text-white hover:border-2 hover:bg-white hover:text-teal-600"
                                    onClick={() => {
                                      setDeleteUserPhoneNumber(
                                        person.mobile_number
                                      );
                                      setDeleteModalOpen(true);
                                    }}
                                  >
                                    Delete user
                                    <span className="sr-only">
                                      , {person.name}
                                    </span>
                                  </button>
                                </td>
                            }

                          </tr>
                        ))
                        : currentTableData.map((person, i) => (
                          <tr key={person.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.mobile_number}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {addCommasToNumber(person.balance)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.card_number}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {moment(person.created_at_sql).tz('Etc/GMT-0').format("llll")}
                            </td>
                            {
                              localStorageData.user.user_role === "ops" ?
                                <td></td> :
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <button
                                    className="rounded-lg border-2 bg-teal-600 p-2 text-white hover:border-2 hover:bg-white hover:text-teal-600"
                                    onClick={() => {
                                      setDeleteUserPhoneNumber(
                                        person.mobile_number
                                      );
                                      setDeleteModalOpen(true);
                                    }}
                                  >
                                    Delete user
                                    <span className="sr-only">
                                      , {person.name}
                                    </span>
                                  </button>
                                </td>
                            }

                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>

              {getUsersLoader ? null : search.length > 0 ? null : (
                <div className="mt-2" id="container">
                  <Pagination
                    currentPage={currentPage}
                    totalCount={getUsersData.length}
                    pageSize={PageSize}
                    onPageChange={(page: any) => setCurrentPage(page)}
                    className="pagination-bar"
                    currentTableData={currentTableData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal open={open} setOpen={setOpen} />
        <RfidModal open={rfidOpen} setOpen={setRfidOpen} />
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          setDeleteUserLoader={setDeleteUserLoader}
          deleteUserPhoneNumber={deleteUserPhoneNumber}
          deleteUserLoader={deleteUserLoader}
        />
      </div>
    </>
  );
};

export default Users;
