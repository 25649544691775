import React from "react";

const MachineStatsLoader = () => {
  return (
    <>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
      <tr className="animate-pulse">
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-44 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-32 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
        <td className="min-w-[150px] border-white/0 py-3  pr-4">
          <div className="h-4 w-20 rounded bg-gray-400"></div>
        </td>
      </tr>
    </>
  );
};

export default MachineStatsLoader;
