import React, { useState } from "react";
import {
  STOCK_LEVELS,
  SALES_AND_USAGE,
  STOCK_MANAGMENT,
  USER_TRANSACTIONS,
  decryptObject,
  COMPANY,
} from "../../../Constant/Constant";
import StockLevels from "./Tabs/StockLevels";
import SalesAndUsage from "./Tabs/SalesAndUsage";
import StockManagment from "./Tabs/StockManagment";
import UserTransactions from "./Tabs/UserTransactions";

const tabs = [
  { name: "My Account", href: "#", current: true },
  { name: "Company", href: "#", current: false },
  { name: "Team Members", href: "#", current: false },
  { name: "Billing", href: "#", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function MachineDetails() {
  const [currentTab, setCurrentTab] = useState(STOCK_LEVELS);

  //localStorage
  const authData = localStorage.getItem("auth");
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);


  return (
    <div className="py-5">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-gray-200 dark:!divide-gray-500 rounded-lg shadow"
          aria-label="Tabs"
        >
          {/* <span
            className={classNames(
              currentTab === MACHINE
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              "group relative min-w-0 flex-1 dark:!bg-gray-900 dark:!text-gray-500 cursor-pointer overflow-hidden rounded-l-lg bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            onClick={() => setCurrentTab(MACHINE)}
          >
            <span>Machine</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentTab === MACHINE ? "bg-indigo-500 dark:!bg-white" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </span> */}

          <span
            className={classNames(
              currentTab === STOCK_LEVELS
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",

              "group relative min-w-0 flex-1 cursor-pointer dark:!bg-gray-900 dark:!text-gray-500 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            onClick={() => setCurrentTab(STOCK_LEVELS)}
          >
            <span>Stock Levels</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentTab === STOCK_LEVELS
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </span>


          {
            localStorageData.user.user_role === COMPANY ? null :
              <span
                className={classNames(
                  currentTab === SALES_AND_USAGE
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",

                  "group relative min-w-0 flex-1 cursor-pointer overflow-hidden dark:!bg-gray-900 dark:!text-gray-500 bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                onClick={() => setCurrentTab(SALES_AND_USAGE)}
              >
                <span>Sales & usage</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    currentTab === SALES_AND_USAGE
                      ? "bg-indigo-500"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </span>
          }



          <span
            className={classNames(
              currentTab === STOCK_MANAGMENT
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",

              "group relative min-w-0 flex-1 cursor-pointer overflow-hidden dark:!bg-gray-900 dark:!text-gray-500 bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            onClick={() => setCurrentTab(STOCK_MANAGMENT)}
          >
            <span>{localStorageData.user.user_role === COMPANY ? "Price": "Update price"}</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentTab === STOCK_MANAGMENT
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </span>

          <span
            className={classNames(
              currentTab === USER_TRANSACTIONS
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              " group relative min-w-0 flex-1 cursor-pointer overflow-hidden rounded-r-lg dark:!bg-gray-900 dark:!text-gray-500 bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
            onClick={() => setCurrentTab(USER_TRANSACTIONS)}
          >
            <span>User transactions</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentTab === USER_TRANSACTIONS
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </span>
        </nav>
      </div>

      {currentTab === STOCK_LEVELS ? (
        <StockLevels />
      ) : currentTab === SALES_AND_USAGE ? (
        <SalesAndUsage />
      ) : currentTab === STOCK_MANAGMENT ? (
        <StockManagment />
      ) : (
        <UserTransactions />
      )}
    </div>
  );
}
