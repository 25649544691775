import React, { useEffect, useState } from "react";
// import StockLevelsTank from "../Components/StockLevelsTank";
// import { getMachineDetails, getMachineSelector } from "Redux/Features/Machines/GetMachineDetails";
// import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import StocksByBatchNumber from "../Components/StocksByBatchNumber";
import RealTimeStocks from "../Components/RealTimeStocks";
import { BASE_URL_TWO, COMPANY, decryptObject } from "Constant/Constant";
import CompanyStocks from "../Components/CompanyStocks";

const StockLevels = () => {
  const { id } = useParams();
  const { type } = useParams();

  //localStorage
  const authData = localStorage.getItem("auth");
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const [stocksLoader, setStocksLoader] = useState(true)
  const [stocksData, setStocksData] = useState([])
  const [realTimeLoader, setRealTimeLoader] = useState(true)
  const [realTimeData, setRealTimeData] = useState([])
  const [stockTab, setStockTab] = useState("realTimeStock")

  useEffect(() => {
    axios.get(`${BASE_URL_TWO}getStocks/${type}/${id}`).then((res: any) => {
      setStocksLoader(false);
      setStocksData(res.data);
    }).catch((err: any) => {
      console.log(err);
    })
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL_TWO}getRealTimeStocks/${type}/${id}`).then((res: any) => {
      setRealTimeLoader(false);
      setRealTimeData(res.data);
    }).catch((err: any) => {
      console.log(err);
    })
  }, []);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <div className="mt-2">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
        </div>
        {
          localStorageData.user.user_role === COMPANY ? null :
            <>
              <div className="hidden sm:block mb-4">
                <nav className="flex space-x-1" aria-label="Tabs">
                  <button
                    className={classNames(
                      stockTab === "batchNumber" ? 'bg-teal-600 text-white' : 'bg-white',
                      'px-5 py-2 mr-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-teal-600 rounded-full'
                    )}
                    onClick={() => setStockTab("batchNumber")}
                  >
                    Stocks Refill By Batch Number
                  </button>
                  <button
                    className={classNames(
                      stockTab === "realTimeStock" ? 'bg-teal-600 text-white' : 'bg-white',
                      'px-5 py-2 mr-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-teal-600 rounded-full'
                    )}
                    onClick={() => setStockTab("realTimeStock")}
                  >
                    Stocks Real Time
                  </button>
                </nav>
              </div>

              <div>
                {
                  stockTab === "batchNumber" ?
                    <StocksByBatchNumber stocksData={stocksData} /> : <RealTimeStocks realTimeData={realTimeData} />
                }
              </div>

            </>
        }

        {
          localStorageData.user.user_role === COMPANY ?
            <CompanyStocks/>
            : null
        }


      </div>
    </div>
  );
};

export default StockLevels;
