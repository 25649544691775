import React, { useState } from "react";
import { usePagination, DOTS } from "./UsePagination";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import classnames from "classnames";
import "./pagination.css";

let siblingCount = 1;

function Pagination(props: {
  currentPage: any;
  totalCount: any;
  pageSize: any;
  onPageChange: any;
  className: any;
  currentTableData: any;
}) {
  const {
    currentPage,
    totalCount,
    pageSize,
    onPageChange,
    className,
    currentTableData,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  // console.log({ lastPage, currentPage, totalCount, siblingCount, pageSize });

  return (
    <div>
      {/* <ul
        className={classnames("pagination-container", {
          [className!]: className,
        })}
      >
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
          }

          return (
            <li
              key={pageNumber}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber as number)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
      </ul> */}

      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 dark:!bg-gray-900">
        <div className="flex flex-1 justify-between sm:hidden">
          <div
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            onClick={onPrevious}
          >
            Previous
          </div>
          <div
            className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}
            onClick={onNext}
          >
            Next
          </div>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700 dark:!text-white">
              Showing{" "}
              <span className="font-medium">{currentTableData.length}</span> of{" "}
              <span className="font-medium">{totalCount}</span> results
            </p>
          </div>
          <div>
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <div
                className={`${
                  currentPage === 1 ? "disabled" : ""
                } relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                onClick={onPrevious}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

              {paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                  return (
                    <div
                      aria-current="page"
                      className="z-10 text-gray-500 border-t-2 border-b-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      key={i+1}
                    >
                      &#8230;
                    </div>
                    // <li className="pagination-item dots">&#8230;</li>
                  );
                }

                return (
                  <li
                    key={pageNumber}
                    className={classnames(
                      "pagination-item dark:!text-white relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0",
                      {
                        selected: pageNumber === currentPage,
                      }
                    )}
                    onClick={() => onPageChange(pageNumber as number)}
                  >
                    {pageNumber}
                  </li>
                );
              })}
              <li
                onClick={onNext}
                className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  currentPage === lastPage ? "disabled" : ""
                }`}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </li>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
