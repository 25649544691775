import React, { useEffect, useState } from 'react';
import IbexTenStackTable from './Ibex/IbexTenStackTable';
import TapalTenStackTable from './Tapal/TapalTenStackTable';
import { BASE_URL, BASE_URL_TWO } from 'Constant/Constant';

interface Tab {
    name: string;
    current: boolean;
    id: number;
    machine_code: String;
}

const CorporateClients: React.FC = () => {
    const [tabs, setTabs] = useState<Tab[]>([
        { name: 'Ibex', current: true, id: 1, machine_code: "3110" },
        { name: 'Tapal', current: false, id: 2, machine_code: "3101" },
    ]);

    const [ibexUserLoader, setIbexUserLoader] = useState(false);
    const [ibexUserLists, setIbexUserLists] = useState([]);

    const [tapalUserLoader, setTapalUserLoader] = useState(false);
    const [tapalUserLists, setTapalUserLists] = useState([]);

    const getIbexList = () => {
        setIbexUserLoader(true);
        const result = fetch(`${BASE_URL_TWO}getCorportateClients/3110`)
            .then((response: any) => response.json(response))
            .catch((error) => console.log("error", error));
        result.then((data) => {
            setIbexUserLoader(false);
            setIbexUserLists(data.corporateClients);
        });
    }

    

    //3101 tapal
    const getTapalList = () => {
        setTapalUserLoader(true);
        const result = fetch(`${BASE_URL_TWO}getCorportateClients/3101`)
            .then((response: any) => response.json(response))
            .catch((error) => console.log("error", error));
        result.then((data) => {
            setTapalUserLoader(false);
            setTapalUserLists(data.corporateClients);
        });
    }

    useEffect(() => {
        getIbexList();
        getTapalList();
    }, []);


    const handleTabs = (tab: Tab) => {
        setTabs(
            tabs.map((t) => ({
                ...t,
                current: t.id === tab.id,
            }))
        );
    };

    return (
        <div>
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <span
                                    key={tab.name}
                                    className={`cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${tab.current
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                        }`}
                                    onClick={() => handleTabs(tab)}
                                >
                                    {tab.name}
                                </span>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>


            <div>
                {tabs.find((tab) => tab.current)?.name === "Ibex" ?
                    <IbexTenStackTable
                        ibexUserLoader={ibexUserLoader}
                        ibexUserLists={ibexUserLists}
                        getIbexList={getIbexList} />
                    :
                    <TapalTenStackTable
                        ibexUserLoader={tapalUserLoader}
                        ibexUserLists={tapalUserLists}
                        getIbexList={getTapalList}
                    />
                }
            </div>
        </div>
    );
};

export default CorporateClients;
