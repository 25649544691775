import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL_TWO, encryptObject } from "Constant/Constant";

export interface loginState {
  loginData: any;
  loginLoader: boolean;
  loginError: string;
}

const initialState: loginState = {
  loginData: {},
  loginLoader: false,
  loginError: "",
};

const loginSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loginLoader = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.loginError = payload;
    },

    setLoginData: (state, { payload }: PayloadAction<any>) => {
      state.loginData = payload;
    },
  },
});

// AppThunk sets the type definitions for the dispatch method
export const loginUser = (
  email: string,
  password: number | string,
  navigateCallback: () => void // Callback function for navigation
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const authData = {
      email: email,
      password: password,
    };

    try {
      const baseURL: string = `${BASE_URL_TWO}adminLogin`;

      const res = await axios({
        method: "post",
        url: baseURL,
        data: authData,
      });

      if (res.data.statusCode === "404") {
        dispatch(setErrors("Email and password is incorrect"));
      } else {
        const encryptedUser = encryptObject({ user: res.data.user });

        localStorage.setItem(
          "auth",
          JSON.stringify(
            Object.assign(
              // { user: res.data.user },
              // { loading: false },
              { encryptedUser }
            )
          )
        );
        navigateCallback();
      }

      dispatch(setLoading(false));
      dispatch(setLoginData(res?.data));
    } catch (error: any) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const { setLoading, setErrors, setLoginData } = loginSlice.actions;

export default loginSlice.reducer;

export const loginSliceSelector = (state: { loginSliceStore: loginState }) =>
  state.loginSliceStore;
