import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL, decryptObject } from "Constant/Constant";

export interface dashboardState {
  locationsByCompanyData: any;
  locationsByCompanyLoader: boolean;
  locationsByCompanyErrors: string;
}

const initialState: dashboardState = {
  locationsByCompanyData: [],
  locationsByCompanyLoader: false,
  locationsByCompanyErrors: "",
};

const locationsByCompany = createSlice({
  name: "LOCATIONS BY COMPANY",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.locationsByCompanyLoader = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.locationsByCompanyErrors = payload;
    },

    setLocationsByCompany: (state, { payload }: PayloadAction<any>) => {
      state.locationsByCompanyData = payload;
    },
  },
});

// AppThunk sets the type definitions for the dispatch method
export const getLocationByCompany = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    //localStorage
    const authData = localStorage.getItem("auth");
    // const localStorageData = JSON.parse(authData);
    const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

    const locationsByCompany = {
      request: {
        method: "getLocationByCompany",
        data: {
          company_code: localStorageData.user.company_code,
        },
      },
    };

    try {
      const baseURL: string = `${BASE_URL}locations`;

      const res = await axios({
        method: "post",
        url: baseURL,
        data: locationsByCompany,
      });

      dispatch(setLoading(false));
      dispatch(setLocationsByCompany(res?.data.response?.data?.locations));
    } catch (error: any) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const { setLoading, setErrors, setLocationsByCompany } =
  locationsByCompany.actions;

export default locationsByCompany.reducer;

export const locationsByCompanySelector = (state: {
  locationsByCompanyStore: dashboardState;
}) => state.locationsByCompanyStore;
