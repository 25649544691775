/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { AiOutlineLogout } from "react-icons/ai";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';

import ProfileDropdownComponent from "./ProfileDropdownComponent";
import LogoutModal from "./LogoutModal";
import Dropdown from "components/dropdown";
import { BASE_URL, decryptObject } from "Constant/Constant";
import { setDarkMode } from '../../Redux/Features/DarkMode/DarkMode';
import noNotificationFound from "../../assets/img/notification.jpeg"
import Oil from "../../assets/img/oil.png";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {

  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);

  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const dispatch = useDispatch();

  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);
  const [open, setOpen] = useState(false);
  const [notificationsLoader, setNotificationsLoader] = useState(false);
  const [notifications, setNotifications] = useState([]);

  //dark mode redux state
  if (darkmode) {
    dispatch(setDarkMode(true));
  } else {
    dispatch(setDarkMode(false));
  }

  useEffect(() => {
    setNotificationsLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getNotificationsByCompany",
          data: {
            company_code: localStorageData.user.company_code,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}notifications`, data)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then((data) => {
      setNotificationsLoader(false);
      setNotifications(data.response.data.notifications);
    });
  }, []);

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d] sm-max:relative">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1 sm-max:hidden">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[300px] xl:gap-2">
        <div
          onClick={() => {
            setOpen(true);
          }}
          className="flex h-full items-center rounded-full bg-teal-500 text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[150px]"
        >
          <p className="pl-3 pr-2 text-xl">
            <AiOutlineLogout className="h-8 w-8 text-white dark:text-white" />
          </p>
          <button
            style={{ fontSize: "20px", fontWeight: "bold" }}
            className={`h-full w-full rounded-full px-3 bg-teal-500 text-center text-sm font-extralight text-white outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm-max:hidden`}
          >
            Logout
          </button>
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300  sm-max:origin-[85%_0%] origin-top-center"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm-max:w-[260px] ">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>
              <div className="h-96 overflow-scroll">
                {notificationsLoader ? (
                  <header className="relative animate-pulse pt-4">
                    <div className="mb-3 h-14 w-full rounded bg-gray-400"></div>

                    <div className="h-14 w-full rounded bg-gray-400"></div>
                  </header>
                ) : (notifications && notifications.length <= 1 ?
                  <img src={noNotificationFound} alt="notification" /> :
                  notifications.map((not) => {
                    return (
                      <button
                        className="mb-3 flex w-full items-center"
                        key={not._id}
                      >
                        <div className="flex h-14 w-[70px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                          <img src={Oil} alt="oil icon" className="" />
                        </div>
                        <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                          <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                            {/* New Update: Horizon UI Dashboard PRO */}
                            {not.brand_name}
                          </p>
                          <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                            {/* A new update for your downloaded item is available! */}
                            {not.message}
                          </p>
                        </div>
                      </button>
                    );
                  })
                )}
              </div>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />

          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <ProfileDropdownComponent
          button={
            <span className="inline-flex h-10 w-10 items-center justify-center cursor-pointer rounded-full bg-teal-500">
              <span className="font-medium leading-none text-white">{localStorageData && localStorageData.user.first_name && localStorageData.user.first_name[0] + "" + localStorageData.user.last_name[0]}</span>
            </span>
          }
          children={<></>}
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
      <LogoutModal open={open} setOpen={setOpen} />
    </nav>
  );
};

export default Navbar;
