import React, { useMemo, useEffect, useState } from "react";
import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import moment from 'moment-timezone';

export default function UserStaticsTenStackTable(props: { tableData: any }) {
  const { tableData } = props;

  const data: any = tableData.map((item: any) => ({
    ...item,
    amount: Number(item.amount).toLocaleString(),
  }));

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: () => <span>Name</span>,
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
      },
      {
        accessorFn: (row: any) => moment(row.created_at).tz('Etc/GMT-0').format('llll'),
        id: "created_at",
        header: () => <span>User Since</span>,
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
      },
      {
        accessorKey: "mobile_number",
        header: () => <span>Phone Number</span>,
        footer: (props: any) => props.column.id,
      },
      {
        accessorKey: "balance",
        header: () => <span>Balance</span>,
        footer: (props: any) => props.column.id,
      },
      {
        accessorKey: "card_number",
        header: () => <span>Card number</span>,
        footer: (props: any) => props.column.id,
      },
    ],
    []
  );

  const [open, setOpen] = useState(() => {
    return window.innerWidth < 1200 ? false : true;
  });

  useEffect(() => {
    const handleResize = () => {
      // Update the state based on window width when resized
      setOpen(window.innerWidth < 468 ? false : true);
    };

    // Add event listener for resize event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Table
        {...{
          data,
          columns,
          open
        }}
      />
    </>
  );
}



function Table({ data, columns, open }: { data: any; columns: any; open: boolean }) {

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });


  return (
    <div className="mb-5 mt-5">
      <div className=" flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-gray-900 ">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      <th></th>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                          >
                            {header.isPlaceholder ? null : (
                              <div className="text-gray-900 dark:!text-white">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {header.column.getCanFilter() ? (
                                  <div className="text-gray-900 dark:!text-white">
                                    {
                                      open ?
                                        <Filter
                                          column={header.column}
                                          table={table}
                                        /> : null
                                    }

                                  </div>
                                ) : null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-gray-50 dark:!bg-gray-900 ">
                  {table.getRowModel().rows.map((row: any) => {
                    return (
                      <tr key={row.id}>
                        {
                          open ? <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-white sm:pl-6">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-teal-600 text-white">
                              <span className="font-medium leading-none text-white">
                                {row && row.original && row.original.name && row.original.name.substring(0, 2)}
                              </span>
                            </span>
                          </td> : <td></td>

                        }

                        {row.getVisibleCells().map((cell: any) => (
                          <td
                            key={cell.id}
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-white sm:pl-6"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="h-5" />
      <div className="w-full sm:flex items-center justify-between">
        {/* first div */}
        <div className="flex flex-wrap sm:flex-nowrap sm:justify-center sm:items-center">
          <button
            className="relative mr-2 inline-flex dark:!bg-gray-900 dark:!text-white items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className="relative inline-flex items-center rounded-md dark:!bg-gray-900 dark:!text-white bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className="relative ml-2 inline-flex items-center rounded-md dark:!bg-gray-900 dark:!text-white bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className="relative ml-2 inline-flex items-center rounded-md dark:!bg-gray-900 dark:!text-white bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="ml-2 flex items-center gap-1">
            <div className="hidden sm:block">Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
        </div>

        {/* second div */}
        <div className="flex flex-wrap sm:flex-nowrap mt-4 sm:mt-0">
          <span className="mr-2 flex items-center gap-1 ">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="block rounded-md border-0 px-2 py-1.5 dark:!bg-gray-900 dark:!text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="block rounded-md dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2 sm:mt-0"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>

    </div>
  );
}

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ReactTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        className="block w-24 rounded-md border-0 py-1.5 pl-3 pr-10 dark:!bg-gray-900 dark:!text-white text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className="block w-24 rounded-md border-0  dark:!bg-gray-900 dark:!text-white py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="block w-36 rounded-md border-0 py-1.5 pl-3 pr-10 dark:!bg-gray-900 dark:!text-white text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    />
  );
}
