import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import thunk from "redux-thunk";

import App from "./App";
import { bottleDispensedState } from "./Redux/Features/Dashboard/GetBottleDispensedByCompany";
import dashboardStore from "./Redux/Features/Dashboard/getPlasticSavedByCompany";
import machinesByCompanyStore from "./Redux/Features/Dashboard/GetMachinesByCompany";
import locationsByCompanyStore from "./Redux/Features/Dashboard/GetLocationsByCompany";
import bottleDispensedByCompanyStore from "./Redux/Features/Dashboard/GetBottleDispensedByCompany";
import userTransactionStore from "./Redux/Features/Machines/UserTransaction";
import darkModeStore from "./Redux/Features/DarkMode/DarkMode";
import loginSliceStore from "./Redux/Features/Auth/Auth";
import getMahcineStore from "./Redux/Features/Machines/GetMachineDetails";

// The AppThunk type will help us in writing type definitions for thunk actions
export type AppThunk = ThunkAction<void, bottleDispensedState, unknown, Action<string>>;

const store = configureStore({
  reducer: {
    // photosStore: photosSliceReducer,
    loginSliceStore: loginSliceStore,
    dashboardStore: dashboardStore,
    machinesByCompanyStore: machinesByCompanyStore,
    locationsByCompanyStore: locationsByCompanyStore,
    bottleDispensedByCompanyStore: bottleDispensedByCompanyStore,
    userTransactionStore: userTransactionStore,
    darkModeStore: darkModeStore,
    getMahcineStore: getMahcineStore
  },
  middleware: [thunk], // Use redux-thunk middleware for handling async actions
});

const AppContainer = () => {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

export default AppContainer;
