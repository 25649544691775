import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL, decryptObject } from "Constant/Constant";

export interface getMachineState {
  machineData: any;
  machineLoader: boolean;
  machineError: string;
}

const initialState: getMachineState = {
  machineData: [],
  machineLoader: false,
  machineError: "",
};

const getMachineSlice = createSlice({
  name: "MACHINE DETAILS",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.machineLoader = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.machineError = payload;
    },

    setMachineDetails: (state, { payload }: PayloadAction<any>) => {
      state.machineData = payload;
    },
  },
});

// AppThunk sets the type definitions for the dispatch method
export const getMachineDetails = (machineId: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    //localStorage
    const authData = localStorage.getItem("auth");
    // const localStorageData = JSON.parse(authData);
    const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

    const transactionsBody = {
      request: {
        method: "getMachineDetails",
        data: {
          machine_id: machineId,
          company_code:localStorageData.user.company_code
        },
      },
    };

    try {
      const baseURL: string = `${BASE_URL}machines`;

      const res = await axios({
        method: "post",
        url: baseURL,
        data: transactionsBody,
      });

      dispatch(setLoading(false));
      if (res.data.response.status.statusCode === 200) {
        dispatch(setMachineDetails(res.data.response.data.machine));
      }
    } catch (error: any) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const { setLoading, setErrors, setMachineDetails } =
  getMachineSlice.actions;

export default getMachineSlice.reducer;

export const getMachineSelector = (state: {
  getMahcineStore: getMachineState;
}) => state.getMahcineStore;

//change selector
//change store
//change slice
