import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL_TWO } from 'Constant/Constant';
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setMachinesByCompany } from 'Redux/Features/Dashboard/GetMachinesByCompany';

export default function UpdatePriceModal(props: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<any>> }) {
    const { open, setOpen } = props;
    let { id } = useParams();
    const dispatch = useDispatch<any>();

    const cancelButtonRef = useRef(null);

    const [machineBrands, setMachineBrands] = useState([]);
    const [machineBrandsLoading, setMachineBrandsLoading] = useState(true);

    const getMachines = () => {
        axios.get(`${BASE_URL_TWO}stockManagment/${id}`).then((res: any) => {
            setMachineBrands(res.data.data)
            setMachineBrandsLoading(false)
        }).catch((error: any) => {
            setMachineBrandsLoading(false)
            console.log(error)
        })
    }

    useEffect(() => {
        getMachines();
    }, [])

    const handlePriceChange = (e: any, id: any) => {
        const { value } = e.target;
        setMachineBrands((prevMachineBrands) =>
            prevMachineBrands.map((machine: any) =>
                machine.id === id ? { ...machine, price: value } : machine
            )
        );
    };

    const handleUpdatePrice = async () => {
        try {
            axios.post(`${BASE_URL_TWO}updatePrice`, {
                type: id.startsWith("3") ? "butterfly" : "oil",
                data: machineBrands
            }).then((res) => {
                toast.success("Prices updated successfully.");
                const updatedData = res?.data.data;
                dispatch(setMachinesByCompany(updatedData));
                setOpen(false);
            }).catch((error) => {
                toast.error("Something went wrong");
            })
            // Assuming you have the updated data from the API response
        } catch (error) {
            // Handle errors
            toast.error("Something went wrong");
        }
    };

    // const handleSubmit = () => {
    //     axios.post("https://davaambackend.up.railway.app/updatePrice", {
    //         type: id.startsWith("3") ? "butterfly" : "oil",
    //         data: machineBrands
    //     }).then((res) => {
    //         console.log("data", res.data.data)
    //         toast.success("Prices updated successfully.");
    //         handleUpdatePrice(res.data.data);
    //         setOpen(false);
    //     }).catch((error) => {
    //         toast.error("Something went wrong");
    //     })
    // }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto mt-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden dark:!bg-gray-900 bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                {
                                    machineBrandsLoading ?
                                        <h1>Loading</h1> :
                                        <div className="dark:!bg-gray-900 mt-5">
                                            <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                <div>
                                                    <h3 className="text-2xl font-bold tracking-tight text-teal-500 sm:text-4xl mb-4">Update Price</h3>
                                                    <div>
                                                        {machineBrands && machineBrands ? (
                                                            machineBrands.map((brand: any, index: any) => (
                                                                <div key={brand.id} className='flex justify-between p-2'>
                                                                    <h1 className='text-black'>{brand.name}</h1>
                                                                    {/* <h1 className='text-black'>{brand.price}</h1> */}
                                                                    {/* <h1 className='text-black'>{brand.id}</h1> */}
                                                                    <div>
                                                                        <input
                                                                            name="price"
                                                                            type="number"
                                                                            value={brand?.price || brand?.litre_price}
                                                                            onChange={(e: any) => handlePriceChange(e, brand.id)}
                                                                            className=" rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div>No machine brands available</div>
                                                        )}
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full cursor-pointer justify-center rounded-md bg-teal-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-600 sm:ml-3 sm:w-auto"
                                                    onClick={handleUpdatePrice}
                                                    ref={cancelButtonRef}
                                                >
                                                    Update Price

                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    onClick={() => setOpen(false)}
                                                    ref={cancelButtonRef}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                }

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}



