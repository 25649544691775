import React from 'react';
import Select from 'react-select';

const MultiSelect = (props: { data: any, setSelectedMachines: any, machineUpdate: any }) => {
    const { data, setSelectedMachines, machineUpdate } = props;

    return (
        <Select
            defaultValue={machineUpdate}
            isMulti
            name="colors"
            options={data}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => setSelectedMachines(e)}
        />
    )
};

export default MultiSelect;