import React, { useState } from 'react';
import IbexTenStackTable from './Ibex/IbexTenStackTable';
import SendNotification from './Components/SendNotification';
import { Tab } from './SendNotificationInterface';

const SendNotifications: React.FC = () => {
    const [tabs, setTabs] = useState<Tab[]>([
        { name: 'Send Notifications Single', current: true, id: 2 },
        { name: 'Send Notifications', current: false, id: 1 },
    ]);

    const handleTabs = (tab: Tab) => {
        setTabs(
            tabs.map((t) => ({
                ...t,
                current: t.id === tab.id,
            }))
        );
    };

    return (
        <div>
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <span
                                    key={tab.name}
                                    className={`cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${tab.current
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                        }`}
                                    onClick={() => handleTabs(tab)}
                                >
                                    {tab.name}
                                </span>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            <div>
                {tabs.find((tab) => tab.current)?.id === 1 ? <IbexTenStackTable /> :
                    <SendNotification />
                }
            </div>
            <h1>
            </h1>
        </div>
    );
};

export default SendNotifications;
