import { COMPANY, allMachines, decryptObject } from "Constant/Constant";
import { timeConverter } from "Constant/TimestampConstant";
// import { pushNotification } from "Constant/NotificationConstant";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

function MachineTable(props: { tableData: any, machineStatusData: any }) {
  const { tableData, machineStatusData } = props;
 
   //localStorage
   const authData = localStorage.getItem("auth");
  //  const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const [machineNumber, setMachineNumber] = useState("")
  const [activeButton, setActiveButton] = useState("All")

  let tableDataFilter = machineNumber && tableData.filter((e: any) =>
    e._id.toLowerCase().includes(machineNumber.toLowerCase()) ||
    e.location.toLowerCase().includes(machineNumber.toLowerCase())
  );

  const tableDataCsv = tableData.map((e: any) => {
    return { ...e, brands: e.brands.map((f: any) => f.name).flat().toString() }
  })

  const mergeData = tableData.map((e: any) => {
    const addData = machineStatusData.find((f: any) => e._id === f.machine_code);
    return { ...e, ...addData }
  })
  let filterDataForMachinesAll = mergeData;
  let filterDataForMachinesActive = mergeData.filter((e: any) => e.status === "g");
  let filterDataForMachinesInactive = mergeData.filter((e: any) => e.status === "r");
  let filterDataForMachinesPending = mergeData.filter((e: any) => e.status === "a");
  let filterDataForMachinesNotDefined = mergeData.filter((e: any) => e.status === undefined);

  let showData = activeButton === "Active" ? filterDataForMachinesActive :
    activeButton === "Inactive" ? filterDataForMachinesInactive :
      activeButton === "Pending" ? filterDataForMachinesPending :
        activeButton === "NotDefined" ? filterDataForMachinesNotDefined : filterDataForMachinesAll

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:!text-gray-500">
            Deployed Machines
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the machines in your account including their machine
            id, locations, type and brands.
          </p>
        </div>
        <div>
          <CSVLink data={tableDataCsv} headers={allMachines} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
        </div>
      </div>

      <div className="mt-3">
        <input
          type="text"
          name="text"
          id="text"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search by machine number"
          onChange={(e: any) => setMachineNumber(e.target.value)}
        />
      </div>
      <div className="mt-3 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-gray-900 ">
                  <tr>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6"
                    >
                      SNO
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Machine Id
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Locations
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Last active
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 sm:pl-6 dark:!text-white"
                    >
                      Action
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {machineNumber ?
                    showData.map((machine: any, key: any) => (
                      <tr key={machine._id}>
                        <td className="text-gray-900 dark:!text-gray-500 sm:pl-0 text-center whitespace-nowrap text-sm font-medium">
                          {key + 1}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine._id}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.location}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.type}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {
                            machineStatusData && machineStatusData.find((e: any) => e.machine_code === machine._id)
                              ? timeConverter(machineStatusData.find((e: any) => e.machine_code === machine._id).last_updated)
                              : null
                          }
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium flex justify-center">
                          <Link to={`/admin/machine-details/${machine._id}/${machine.type}`} className="text-center">
                            <button
                              type="button"
                              className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                              Visit{" "}
                            </button>
                          </Link>
                        </td>
                      </tr>
                    )) :
                    tableData.map((machine: any, key: number) => (
                      <tr key={machine._id}>
                        <td className="text-gray-900 dark:!text-gray-500 sm:pl-0 text-center whitespace-nowrap text-sm font-medium">
                          {key + 1}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine._id}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.location}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {machine.type}
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {
                            machineStatusData && machineStatusData.find((e: any) => e.machine_code === machine._id)
                              ? machineStatusData.find((e: any) => e.machine_code === machine._id).status === "r"
                                ?
                                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                  Inactive
                                </span>
                                : machineStatusData.find((e: any) => e.machine_code === machine._id).status === "g"
                                  ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    Active
                                  </span>
                                  : machineStatusData.find((e: any) => e.machine_code === machine._id).status === "a"
                                    ? <span className="inline-flex items-center rounded-md bg-amber-50 px-2 py-1 text-xs font-medium text-amber-700 ring-1 ring-inset ring-amber-600/20">
                                      Pending
                                    </span>
                                    : <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                      Not defined
                                    </span>
                              : <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                Not defined
                              </span>
                          }
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                          {
                            machineStatusData && machineStatusData.find((e: any) => e.machine_code === machine._id)
                              ? timeConverter(machineStatusData.find((e: any) => e.machine_code === machine._id).last_updated)
                              : null
                          }
                        </td>
                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium flex justify-center">
                          <Link to={`/admin/machine-details/${machine._id}/${machine.type}`} className="text-center">
                            <button
                              type="button"
                              className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                              Visit{" "}
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MachineTable;
