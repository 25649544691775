export default function PrivacyPolicy() {
  return (
    <div className="px-20 py-10">
      <h1 className="text-center text-9xl	font-extrabold leading-7 text-gray-900 sm:truncate sm:text-5xl sm:tracking-tight">
        DAVAAM PRIVACY POLICY
      </h1>
      <p className="mt-10 text-lg font-medium	">
        Davaam Pvt Ltd built the Davaam app as a Free app. This SERVICE is
        provided by Davaam Pvt Ltd at no cost and is intended for use as is.
      </p>
      <p className="mt-5 text-lg font-medium	">
        This privacy policy outlines the information that our app collects, how
        it is used, and the steps we take to protect it. Our goal is to be
        transparent about the data we collect and to give you control over your
        personal information. If you choose to use our Service, then you agree
        to the collection and use of information in relation to this policy.
      </p>
      <p className="mt-5 text-lg font-medium	">
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which are accessible at Davaam unless otherwise
        defined in this Privacy Policy.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        INFORMATION COLLECTION AND USE
      </h1>

      <ul className="mt-3 px-5">
        <li className="list-disc font-medium">
          Personal Information: When you use our app, we may ask you to provide
          personal information such as your name, email address, and phone
          number. This information is used to provide you with the services you
          request and to communicate with you about your use of the app.
        </li>
        <li className="mt-2 list-disc font-medium">
          Location Information: Our app may collect information about your
          location to provide you with relevant content and services. You can
          choose not to allow us to access your location information by
          adjusting your device's settings.
        </li>
        <li className="mt-2 list-disc font-medium">
          Usage Information: We collect information about how you use our app,
          including the features you use, the pages you visit, and the length of
          your sessions. This information helps us to improve the app and
          provide you with a better user experience.
        </li>
      </ul>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        LOG DATA
      </h1>

      <p className="mt-3 text-lg font-medium	">
        We want to inform you that whenever you use our Service, in a case of an
        error in the app we collect data and information (through third-party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing our Service, the time and date of your use of the Service, and
        other statistics.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        CAMERA USE FOR SCANNING
      </h1>

      <p className="mt-3 text-lg font-medium	">
        Our app may use the camera feature on your device for scanning purposes,
        such as scanning barcodes or QR codes. This information is used to
        provide you with relevant content and services.
      </p>

      <p className="mt-3 text-lg font-medium	">
        We take the security of the information obtained through scanning very
        seriously and will store it securely on our servers. We will not share
        this information with third parties unless required by law or for the
        purpose of providing you with the services you request.
      </p>

      <p className="mt-3 text-lg font-medium	">
        You can choose not to allow us to access your camera by adjusting your
        device's settings. If you have any questions or concerns about our use
        of the camera feature, please contact us at info@davaam.pk.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        DATA SECURITY
      </h1>

      <p className="mt-3 text-lg font-medium	">
        We take the security of your personal information seriously and use a
        variety of security measures to protect it, including encryption and
        secure servers. However, no method of transmission over the internet or
        electronic storage is 100% secure, and we cannot guarantee absolute
        security.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        SHARING OF INFORMATION
      </h1>

      <p className="mt-3 text-lg font-medium	">
        We will not sell, rent, or share your personal information with third
        parties for their own marketing purposes without your consent.
      </p>

      <p className="mt-3 text-lg font-medium	">
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul className="mt-3 px-5">
        <li className="list-disc font-medium">To facilitate our Service</li>
        <li className="mt-2 list-disc font-medium">
          To provide the Service on our behalf
        </li>
        <li className="mt-2 list-disc font-medium">
          To perform Service-related services or
        </li>
        <li className="mt-2 list-disc font-medium">
          To assist us in analyzing how our Service is used.
        </li>
      </ul>

      <p className="mt-3 text-lg font-medium	">
        We want to inform users of this Service that these third parties have
        access to their Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        LINKS TO OTHER SITES
      </h1>

      <p className="mt-3 text-lg font-medium	">
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        CHILDREN’S PRIVACY
      </h1>

      <p className="mt-3 text-lg font-medium	">
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13 years of age. In the case we discover that a child under 13 has
        provided us with personal information, we immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to do the necessary actions.
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        CHANGES TO THIS PRIVACY POLICY
      </h1>

      <p className="mt-3 text-lg font-medium	">
        We may update this privacy policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We will notify you of any changes by posting the new Privacy Policy on
        this page. This policy is effective as of 2023-12-31
      </p>

      <h1 className="mt-5	text-4xl font-extrabold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight">
        CONTACT US
      </h1>

      <p className="mt-3 text-lg font-medium	">
        If you have any questions or concerns about our privacy policy or the
        information we collect, please contact us at info@davaam.pk.
      </p>
    </div>
  );
}
