import { decryptObject } from "Constant/Constant";
import React from "react";
import { useNavigate } from "react-router-dom";

function useOutsideAlerter(ref: any, setX: any): void {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // function handleClickOutside(event: React.MouseEvent<HTMLElement>) {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

const ProfileDropdownComponent = (props: {
  button: JSX.Element;
  children: JSX.Element;
  classNames: string;
  animation?: string;
}) => {
  const navigate = useNavigate();

    //localStorage
    const authData = localStorage.getItem("auth");
    // const localStorageData = JSON.parse(authData);

    const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const { button, children, classNames, animation } = props;
  const wrapperRef = React.useRef(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);
  useOutsideAlerter(wrapperRef, setOpenWrapper);

  const handleLogout = () => {
    localStorage.clear();
    navigate("auth");
  };

  return (
    <div ref={wrapperRef} className="relative flex">
      <div className="flex" onMouseDown={() => setOpenWrapper(!openWrapper)}>
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : "origin-top-right transition-all duration-300 ease-in-out"
        } ${openWrapper ? "scale-100" : "scale-0"}`}
      >
        <div className="flex h-32 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
          <div className="ml-4 mt-3">
            <div className="flex items-center gap-2">
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                👋 Hey, {localStorageData.user.first_name +" "+localStorageData.user.last_name || "John"}
              </p>{" "}
            </div>
          </div>
          <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

          <div className="ml-4 mt-3 flex flex-col">
            <div className="text-sm text-gray-800 dark:text-white hover:dark:text-white">
              Profile Details
            </div>
            <div className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
              onClick={() => handleLogout}
            
            >
              Newsletter Settings
            </div>
            {/* <button
              className="mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-red-500 bg-teal-500" 
              // onClick={() => handleLogout}
            >
              Log Out
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdownComponent;
