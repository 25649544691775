import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useSelector, useDispatch } from "react-redux";
import {
  machinesByCompanySelector,
} from "../../../../Redux/Features/Dashboard/GetMachinesByCompany";
import {
  userTransactionSelector,
  getUserTransactions,
} from "../../../../Redux/Features/Machines/UserTransaction";
import MachineStatsLoader from "./MachineStatsLoader";
import MachineTenStackTable from "./MachineTenStackTable";
import "./css/machine.css"
import { CSVLink } from "react-csv";
import { BASE_URL_TWO, dashboardMachineStatics, decryptObject } from "Constant/Constant";
import axios from "axios";
import MachineStatisticsSelect from "components/AdminsComponent/MachineStatisticsSelect";

function MachineStatics(props: { tableData: any }) {
  const dispatch = useDispatch<any>();

  const [data, setData] = useState([]);

  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  //active machines list
  const { machinesByCompanyData, machinesByCompanyLoader } = useSelector(
    machinesByCompanySelector
  );

  //user transaction table by machine
  const { userTransactionsData, userTransactionsLoader, userTransactionError } =
    useSelector(userTransactionSelector);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    axios.get(`${BASE_URL_TWO}getMachinesByCompany`).then((res) => {
      setData(res.data.data)
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  useEffect(() => {
    dispatch(getUserTransactions(selectedOption && (selectedOption.machine_code || selectedOption.machineCode)));
  }, [dispatch, selectedOption]);

  return (
    <Card extra={`w-full sm:overflow-auto px-6 ${userTransactionsData.length === 0 ? "h-96" : ""}`}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Machine Statistics
        </div>

        {machinesByCompanyLoader ? null : (
          <div className="">
            <CSVLink
              data={userTransactionsData}
              headers={dashboardMachineStatics}
              className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
              Export as csv
            </CSVLink>
          </div>
        )}
      </header>
      {
        <MachineStatisticsSelect
          data={localStorageData.user.machines ? localStorageData.user.machines : data}
          selectedOption={selectedOption}
          handleSelectChange={handleSelectChange}
        />
      }

      <tbody className="divide-y divide-gray-200 bg-white dark:!bg-navy-800">
        {userTransactionsLoader ?
          <MachineStatsLoader />
          : userTransactionsData.length ===
            0 ? (
            <div className="ring-black mt-3 overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-navy-800">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      SNO
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Rfid
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                    >
                      Created at
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white dark:!bg-navy-800">

                  <tr>
                    <td
                      colSpan={7}
                      className="w-full py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500"
                    >
                      No data found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          ) : (
            <MachineTenStackTable tableData={userTransactionsData} />

          )}
      </tbody>

      <div className="mb-3 mt-3"></div>
    </Card>
  );
}

export default MachineStatics;
