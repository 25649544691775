import Select from "react-select";
import { darkModeSelector } from "Redux/Features/DarkMode/DarkMode";
import { useSelector } from "react-redux";
import { allMachinesLists } from 'Constant/Constant';

const MachineStatisticsSelect = (props: { data: any, selectedOption: any, handleSelectChange: any }) => {
    const { data, selectedOption, handleSelectChange } = props;

    const { darkMode } = useSelector(darkModeSelector);

    const machineDetails = allMachinesLists.filter((e: any) => {
        return data.some((f: any) => {
            return f.machine_code === e.machineCode;
        });
    });

    const reactSelectMachineLocation = machineDetails.map((item: any) => ({
        ...item,
        label: `${item.location_name || item.machineName} - ${item.machine_code || item.machineCode}`,
        value: item.machine_code || item.machineCode,
    }));

    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 35,
            minHeight: 35,
            background: `${darkMode ? "#1B254A" : ""}`,
        }),
        option: (styles: any) => {
            // const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: `${darkMode ? "#1B254A" : ""}`,
                color: `${darkMode ? "#fff" : "#1B254A"}`,
                height: 45,
                minHeight: 45,
            };
        },
    };

    return (
        <div className="mt-3">
            <Select
                className="dark:!text-white"
                options={reactSelectMachineLocation}
                placeholder="Select machine"
                value={selectedOption}
                onChange={handleSelectChange}
                styles={customStyles}
            />
        </div>
    )
}

export default MachineStatisticsSelect;