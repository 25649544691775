/* eslint-disable no-lone-blocks */
import React from "react";
import Card from "components/card";
import UserStaticsTenStackTable from "./UserStaticsTenStackTable";
import { CSVLink } from "react-csv";
import { userStaticsHeader } from "Constant/Constant";

function UserStaticsTable(props: { tableData: any }) {
  const { tableData } = props;


  const ids: any = tableData.map(({ mobile_number }: any) => mobile_number);
  const filteredTableData: any = tableData.filter(({ mobile_number }: any, index: number) => !ids.includes(mobile_number, index + 1));


  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {`Mobile App Users (${filteredTableData.length})`}
        </div>
        <div>
          <CSVLink data={filteredTableData} headers={userStaticsHeader} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
          {/* <button className="bg-teal-600 p-2 rounded text-white">Download CSV</button> */}
        </div>
      </header>
      <UserStaticsTenStackTable tableData={filteredTableData} />
    </Card>
  );
}

export default UserStaticsTable;
