import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BASE_URL, addCommasToNumber, barChartCustomData, decryptObject } from "Constant/Constant";

const products = [
  {
    id: 1,
    name: "Total Revenue",
    href: "#",
    imageSrc: "",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  {
    id: 1,
    name: "Total Quantity",
    href: "#",
    imageSrc:
      "https://images.unsplash.com/photo-1589395937921-fddc324ccdd2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1413&q=80",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  {
    id: 1,
    name: "Typical Order Quantity",
    href: "#",
    imageSrc:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  {
    id: 1,
    name: "Basic Tee",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  {
    id: 1,
    name: "Basic Tee",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  // More products...
];

export default function Usage() {
  //localStorage
  const authData = localStorage.getItem("auth");
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);
  // const localStorageData = JSON.parse(authData);
  const { id } = useParams();

  //bar Chart
  const [barChartLoader, setBarChartLoader] = useState(false);
  const [usage, setUsage] = useState({
    totalRevenue: 0,
    totalTransaction: 0,
    totalVolume: 0,
    typicalOrderVolume: 0,
    typicalTransactionSize: 0,
    uniqueUsers: 0,
  });

  useEffect(() => {
    setBarChartLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getMachineDetails",
          data: {
            company_code: localStorageData.user.company_code,
            machine_id: id,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}machines`, data)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then((data: any) => {
      setBarChartLoader(false);
      setUsage({
        totalRevenue: data.response.data.machine.sales_page.total_revenue,
        totalTransaction:
          data.response.data.machine.sales_page.total_transactions,
        totalVolume: data.response.data.machine.sales_page.total_volume,
        typicalOrderVolume:
          data.response.data.machine.sales_page.typical_order_vol,
        typicalTransactionSize:
          data.response.data.machine.sales_page.typical_transaction_size,
        uniqueUsers: data.response.data.machine.sales_page.unique_users,
      });
    });
  }, []);

  return (
    <div className="">
      <div className="mx-auto max-w-2xl px-4 py-5 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
        {/* <h2 className="text-2xl font-bold tracking-tight text-gray-900">Customers also purchased</h2> */}

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {/*  */}
          <div className="group relative">
            <div className="aspect-h-1 aspect-w-1 lg:aspect-none w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-80">
              <img
                src={
                  "https://st4.depositphotos.com/3266387/19645/i/450/depositphotos_196456056-stock-photo-cooking-oil-plastic-bottle-white.jpg"
                }
                alt={"product"}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  <span>
                    <span aria-hidden="true" className="absolute inset-0 dark:!text-gray-500" />
                    Total Revenue
                  </span>
                </h3>
                {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
              </div>
              <p className="text-sm font-medium text-gray-900 dark:!text-gray-500">
                {
                  barChartLoader ?
                    <tr className="animate-pulse">
                      <td className="min-w-[50px] border-white/0">
                        <div className="h-4 w-16 rounded bg-gray-400"></div>
                      </td>
                    </tr> :
                    <>
                      PKR {addCommasToNumber(usage.totalRevenue)}
                    </>
                }
              </p>
            </div>
          </div>

          {/*  */}
          <div className="group relative">
            <div className="aspect-h-1 aspect-w-1 lg:aspect-none w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-80">
              <img
                src={
                  "https://st4.depositphotos.com/3266387/19645/i/450/depositphotos_196456056-stock-photo-cooking-oil-plastic-bottle-white.jpg"
                }
                alt={"product"}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  <span>
                    <span aria-hidden="true" className="absolute inset-0 dark:!text-gray-500" />
                    Total Quantity
                  </span>
                </h3>
                {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
              </div>
              <p className="text-sm font-medium text-gray-900 dark:!text-gray-500">
                {
                  barChartLoader ?
                    <tr className="animate-pulse">
                      <td className="min-w-[50px] border-white/0">
                        <div className="h-4 w-16 rounded bg-gray-400"></div>
                      </td>
                    </tr> :
                    <>
                      PKR {addCommasToNumber(usage.totalVolume)}
                    </>
                }
              </p>
            </div>
          </div>

          {/*  */}
          <div className="group relative">
            <div className="aspect-h-1 aspect-w-1 lg:aspect-none w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-80">
              <img
                src={
                  "https://st4.depositphotos.com/3266387/19645/i/450/depositphotos_196456056-stock-photo-cooking-oil-plastic-bottle-white.jpg"
                }
                alt={"product"}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  <span>
                    <span aria-hidden="true" className="absolute inset-0 dark:!text-gray-500" />
                    Typical Order Quantity
                  </span>
                </h3>
                {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
              </div>
              <p className="text-sm font-medium text-gray-900 dark:!text-gray-500">
                {
                  barChartLoader ?
                    <tr className="animate-pulse">
                      <td className="min-w-[50px] border-white/0">
                        <div className="h-4 w-16 rounded bg-gray-400"></div>
                      </td>
                    </tr> :
                    <>
                      PKR {addCommasToNumber(usage.typicalOrderVolume)}
                    </>
                }
              </p>
            </div>
          </div>

          {/*  */}
          <div className="group relative">
            <div className="aspect-h-1 aspect-w-1 lg:aspect-none w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-80">
              <img
                src={
                  "https://st4.depositphotos.com/3266387/19645/i/450/depositphotos_196456056-stock-photo-cooking-oil-plastic-bottle-white.jpg"
                }
                alt={"product"}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  <span>
                    <span aria-hidden="true" className="absolute inset-0 dark:!text-gray-500" />
                    Typical Transaction Size
                  </span>
                </h3>
                {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
              </div>
              <p className="text-sm font-medium text-gray-900 dark:!text-gray-500">
                {
                  barChartLoader ?
                    <tr className="animate-pulse">
                      <td className="min-w-[50px] border-white/0">
                        <div className="h-4 w-16 rounded bg-gray-400"></div>
                      </td>
                    </tr> :
                    <>
                      PKR {addCommasToNumber(usage.typicalTransactionSize)}
                    </>
                }
              </p>
            </div>
          </div>

          {/*  */}
          <div className="group relative">
            <div className="aspect-h-1 aspect-w-1 lg:aspect-none w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-80">
              <img
                src={
                  "https://st4.depositphotos.com/3266387/19645/i/450/depositphotos_196456056-stock-photo-cooking-oil-plastic-bottle-white.jpg"
                }
                alt={"product"}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  <span>
                    <span aria-hidden="true" className="absolute inset-0 dark:!text-gray-500" />
                    Unique Users
                  </span>
                </h3>
                {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
              </div>
              <p className="text-sm font-medium text-gray-900 dark:!text-gray-500">
                {
                  barChartLoader ?
                    <tr className="animate-pulse">
                      <td className="min-w-[50px] border-white/0">
                        <div className="h-4 w-16 rounded bg-gray-400"></div>
                      </td>
                    </tr> :
                    <>
                      PKR {addCommasToNumber(usage.uniqueUsers)}
                    </>
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
