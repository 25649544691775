import { useState, useEffect } from "react";
import { BASE_URL_TWO, machineTableData } from "Constant/Constant";
import PointsShareTable from "./components/PointsShareTable";
import TableLoader from "components/TableLoader/TableLoader";
import axios from "axios";

const PointsShare = () => {

  const [machineLoader, setMachineLoader] = useState(false);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    setMachineLoader(true);
    const apiUrl = `${BASE_URL_TWO}webGetPointsShare`;

    axios.get(apiUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        setMachineData(response.data.pointsShare);
        setMachineLoader(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMachineLoader(false);
      });
  }, []);

  return (
    <div className="py-5 bg-white m-3 rounded-md dark:!bg-navy-900">
      <div>
        {!machineLoader ? (
          <PointsShareTable tableData={machineData}/>
        ) : (
          <TableLoader tableLoaderData={machineTableData} />
        )}
      </div>
    </div>
  );
};

export default PointsShare;
