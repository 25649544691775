import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { BASE_URL } from "Constant/Constant";

type Inputs = {
  userName: string;
  phoneNumber: number;
  pin: number;
};

function Modal(props: { open: any; setOpen: any }) {
  const { open, setOpen } = props;
  const cancelButtonRef = useRef(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<Inputs>();

  const [addUserLoader, setAdduserLoader] = useState(false);
  //   const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setAdduserLoader(true);

    const staticsBody = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "addDavaamCardUser",
          data: {
            mobile_number: data.phoneNumber,
            pin: data.pin,
            username: data.userName,
          },
        },
      }),
    };

    const staticsResult = fetch(
      `${BASE_URL}machines/user`,
      staticsBody
    );
    staticsResult
      .then((response: any) => response.json(response))
      .then((data: any) => {
        setAdduserLoader(false);
        setOpen(false);
        toast.success("User added successfully!");
        reset();
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden dark:!bg-gray-900 bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={handleSubmit(onSubmit)} className="dark:!bg-gray-900">
                  <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="">
                      <h3 className="text-2xl font-bold tracking-tight text-teal-500 sm:text-4xl mb-4">Add user</h3>
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          User name
                        </label>
                        <div className="mt-2">
                          <input
                            id="username"
                            name="username"
                            type="text"
                            className="w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                            placeholder="John Doe"
                            {...register("userName", { required: true })}
                          />
                        </div>

                        {errors.userName && (
                          <span className="text-red-500">
                            User name is required
                          </span>
                        )}
                      </div>

                      <div className="mt-3 sm:col-span-4">
                        <label
                          htmlFor="phoneNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone number{" "}
                        </label>
                        <div className="mt-2">
                          <input
                            id="phoneNumber"
                            name="phoneNumber"
                            type="number"
                            autoComplete="phoneNumber"
                            className=" w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                            placeholder="03*********"
                            {...register("phoneNumber", {
                              required: true,
                              pattern:
                                /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
                            })}
                          />
                        </div>
                        {errors.phoneNumber && (
                          <span className="text-red-500">
                            Phone number is required
                          </span>
                        )}
                      </div>

                      <div className="mt-3 sm:col-span-4">
                        <label
                          htmlFor="pin"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Pin{" "}
                        </label>
                        <div className="mt-2">
                          <input
                            id="pin"
                            name="pin"
                            type="number"
                            autoComplete="pin"
                            className=" w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                            placeholder="1234"
                            {...register("pin", {
                              required: true,
                              pattern: /^[1-9][0-9]{3}$/,
                            })}
                          />
                        </div>
                        {errors.pin && (
                          <span className="text-red-500">Pin is required</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {addUserLoader ? (
                    <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                        Loading...
                      </button>
                    </div>
                  ) : (
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <input
                        className="inline-flex w-full cursor-pointer justify-center rounded-md bg-teal-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-600 sm:ml-3 sm:w-auto"
                        type="submit"
                         value="Add user"
                      />

                      <button type="button"></button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
