import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  locationsByCompanySelector,
  getLocationByCompany,
} from "../../../../../Redux/Features/Dashboard/GetLocationsByCompany";
import { useParams } from "react-router-dom";
const Machine = () => {
  const dispatch = useDispatch<any>();
  const { id } = useParams();

  const {
    locationsByCompanyData,
    locationsByCompanyLoader,
    locationsByCompanyErrors,
  } = useSelector(locationsByCompanySelector);

  useEffect(() => {
    dispatch(getLocationByCompany());
  }, [dispatch]);

  const locationBrands = locationsByCompanyData.find((b: any) => b._id === id);

  return (
    <div className="py-5">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50 dark:!bg-gray-900 ">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Machine id
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Brand
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white"
                      >
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {locationBrands.machines.map((person: any) => (
                      <tr key={person.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                          {person.location}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                          {person._id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                          {person.brands.map((e: any) => `${e.name}, `)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                          {person.type}
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Machine;
