import Select from "react-select";
import { useState, useEffect } from "react";
import { BASE_URL, productDetailsTable, productTableData } from "Constant/Constant";
import ProductModal from "./ProductModal";
import TableLoader from "components/TableLoader/TableLoader";
import { CSVLink } from "react-csv";
// import ProductDetailsLoader from "./ProductDetailsLoader";

function ProductTable(props: { brandsData: any }) {
  const { brandsData } = props;

  const [selectedOption, setSelectedOption] = useState(null);
  const [brandsTableLoader, setBrandsTableLoader] = useState(false);
  const [brandsTableData, setBrandsTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [productDetailsData, setProductDetailsData] = useState({
    machineId: "",
    price: "",
    brandName: "",
  });

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const convertedBrands = brandsData.map((item: any) => {
    return { label: item, value: item };
  });

  const getMachineInfoByBrandName = () => {
    //table
    setBrandsTableLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getMachineInfoByBrandName",
          data: {
            brand_name: selectedOption === null ? "" : selectedOption.value,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}brands`, data)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then((data) => {
      setBrandsTableLoader(false);
      setBrandsTableData(data?.response?.data?.machines);
    });
  };

  useEffect(() => {
    getMachineInfoByBrandName();
  }, [selectedOption]);

  return (
    <div className="py-5">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900 dark:!text-gray-500">
              Users
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their machine,
              location, product price and edit.
            </p>
          </div>
          <div className="md:w-32 sm:mt-5 md:m-0">
            <CSVLink
              data={brandsTableData}
              headers={productDetailsTable}
              className="block rounded-md bg-teal-600 px-1 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
              Export as csv
            </CSVLink>
          </div>
        </div>
        <div className="mt-3">
          <Select
            options={convertedBrands}
            placeholder="Select brand"
            value={selectedOption}
            onChange={handleSelectChange}
          />
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
                {brandsTableLoader ? (
                  <TableLoader tableLoaderData={productTableData} />
                ) : (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 dark:!bg-navy-800">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-gray-500 sm:pl-6"
                        >
                          Machine
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-gray-500"
                        >
                          Location
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-gray-500"
                        >
                          Product price
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-gray-500"
                        >
                          Edit
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {
                        brandsTableData.length === 0 ?
                          <tr>
                            <td
                              colSpan={4}
                              className="w-full py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500"
                            >
                              No data found
                            </td>
                          </tr>
                          : brandsTableData.map((person: any) => (
                            <tr key={person.machine_id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                                {person.machine_id}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                {person.location}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                {person.price}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                                <button
                                  onClick={() => {
                                    setOpen(true);
                                    setProductDetailsData({
                                      machineId: person.machine_id,
                                      price: person.price,
                                      brandName: selectedOption,
                                    });
                                  }}
                                  className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductModal
        open={open}
        setOpen={setOpen}
        productDetailsData={productDetailsData}
      />
    </div>
  );
}

export default ProductTable;
