import { useState } from "react";
import Card from "components/card";
import ColumnChart from "components/charts/ColumnChart";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { addCommasToNumber, barChartHead, decryptObject } from "Constant/Constant";
import { CSVLink } from "react-csv";
import pendingImage from "../../../../assets/img/work_in_progress.jpg"

type ChartProps = {
  barChartDataProps: any;
  totalRevenue: any;
  totalTransaction: any;
  barChartLoader: boolean;
};

const FLAT_CHART_TAB_WEEKLY = "weekly";
const FLAT_CHART_TAB_MONTHLY = "monthly";

const BarChart: React.FC<ChartProps> = ({
  barChartDataProps,
  totalRevenue,
  totalTransaction,
  barChartLoader,
}) => {
  const [barChartTab, setBarChartTab] = useState(FLAT_CHART_TAB_MONTHLY);
  const [barChartOption, setBarChartOption] = useState("transaction");

  const monthlyDatesArr =
    barChartDataProps &&
    barChartDataProps.monthly.revenue.map((e: any) => {
      return Object.keys(e)[0];
    });
  const weeklyDatesArr =
    barChartDataProps &&
    barChartDataProps.weekly.revenue.map((e: any) => {
      return Object.keys(e)[0];
    });

  const monthlyRevenue =
    barChartDataProps &&
    barChartDataProps.monthly.revenue.map((e: any) => {
      return Object.values(e)[0];
    });

  const weeklyRevenue =
    barChartDataProps &&
    barChartDataProps.weekly.revenue.map((e: any) => {
      return Object.values(e)[0];
    });

  const monthlyTransaction =
    barChartDataProps &&
    barChartDataProps.monthly.transaction.map((e: any) => {
      return Object.values(e)[0];
    });

  const weeklyTransaction =
    barChartDataProps &&
    barChartDataProps.weekly.transaction.map((e: any) => {
      return Object.values(e)[0];
    });

  const barChartDataWeeklyRevenue = [
    {
      name: "Transactions", //transactions - revenue
      data:
        barChartOption === "transaction" &&
          barChartTab === FLAT_CHART_TAB_MONTHLY
          ? monthlyTransaction
          : barChartOption === "transaction" &&
            barChartTab === FLAT_CHART_TAB_WEEKLY
            ? weeklyTransaction
            : barChartOption === "revenue" &&
              barChartTab === FLAT_CHART_TAB_WEEKLY
              ? weeklyRevenue
              : monthlyRevenue, // dataRevenue - dataTransaction
      color: "#6AD2Fa",
    },
  ];

  const barChartOptionsWeeklyRevenue = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ["#ff3322", "#faf"],
    tooltip: {
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
      theme: "dark",
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
    },
    //dates - months
    xaxis: {
      categories:
        barChartTab === FLAT_CHART_TAB_MONTHLY
          ? monthlyDatesArr
          : weeklyDatesArr,
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "20px",
      },
    },
  };

  const revenueMonthly = barChartDataProps.monthly.revenue.map((item: any) => {
    const [revenueMonthly, revnueMonthlyNumbers] = Object.entries(item)[0];
    return { revenueMonthly, revnueMonthlyNumbers };
  });
  const revenueWeekly = barChartDataProps.weekly.revenue.map((item: any) => {
    const [revenueWeekly, revenueWeeklyNumbers] = Object.entries(item)[0];
    return { revenueWeekly, revenueWeeklyNumbers };
  });

  const transactionMonthly = barChartDataProps.monthly.transaction.map((item: any) => {
    const [transactionMonthly, transactionMonthlyNumber] = Object.entries(item)[0];
    return { transactionMonthly, transactionMonthlyNumber };
  });
  const transactionWeekly = barChartDataProps.weekly.transaction.map((item: any) => {
    const [transactionWeekly, transactionWeeklyNumbers] = Object.entries(item)[0];
    return { transactionWeekly, transactionWeeklyNumbers };
  });

  const combinedArray = revenueWeekly.concat(revenueMonthly, transactionMonthly, transactionWeekly);

  const data = [
    {
      weeks: [...revenueWeekly, ...transactionWeekly],
      months: [...revenueMonthly, ...transactionMonthly],
    },
  ];

  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl p-3 text-center">

      <>
        <div className="flex justify-start  mb-2">
          <CSVLink
            data={data}
            headers={barChartHead}
            className="block w-32 rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
        </div>
        {!barChartLoader ? (
          <>
            <div className="mb-auto flex items-center justify-between px-6">
              <div className="mb-6 flex items-center justify-center">
                <select
                  className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white"
                  value={barChartOption}
                  onChange={(e) => setBarChartOption(e.target.value)}
                >
                  <option value="transaction">Transaction</option>
                  <option value="revenue">Revenue</option>
                </select>
              </div>
              <div className="mb-6 flex items-center justify-center">
                <button
                  className={`rounded-md ${barChartTab === FLAT_CHART_TAB_WEEKLY
                    ? "bg-teal-500 text-white"
                    : "bg-white text-gray-900"
                    }  mr-1 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white`}
                  onClick={() => {
                    setBarChartTab(FLAT_CHART_TAB_WEEKLY);
                  }}
                >
                  Weekly
                </button>
                <button
                  className={`rounded-md ${barChartTab === FLAT_CHART_TAB_MONTHLY
                    ? "bg-teal-500 text-white"
                    : "bg-white text-gray-900"
                    }  px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white`}
                  onClick={() => {
                    setBarChartTab(FLAT_CHART_TAB_MONTHLY);
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>

            <div className="md:mt-16 lg:mt-0">
              <div className="h-[250px] w-full xl:h-[350px]">
                <ColumnChart
                  chartData={barChartDataWeeklyRevenue}
                  chartOptions={barChartOptionsWeeklyRevenue}
                />
              </div>
            </div>

            <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <div className="h-2 w-2 rounded-full bg-brand-500" />
                  <p className="ml-1 text-sm font-normal text-gray-600">
                    Total Revenue
                  </p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
                  PKR {addCommasToNumber(totalRevenue)}
                </p>
              </div>

              <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
                  <p className="ml-1 text-sm font-normal text-gray-600">
                    Total Transactions
                  </p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                  {addCommasToNumber(totalTransaction)}
                </p>
              </div>
            </div>
          </>
        ) : (
          <SkeletonLoader />
        )}
      </>
    </Card>
  );
};

export default BarChart;
