import React, { useState } from "react";
import Sales from "./SalesAndUsageTabs/Sales";
import Usage from "./SalesAndUsageTabs/Usage";

const tabs = [
  { name: "My Account", href: "#", current: false },
  { name: "Company", href: "#", current: false },
  { name: "Team Members", href: "#", current: true },
  { name: "Billing", href: "#", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SalesAndUsage = () => {
  const [currentTab, setCurrentTab] = useState("sales");
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <span
              className={classNames(
                currentTab === "sales"
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
              )}
              onClick={() => setCurrentTab("sales")}
            >
              Sales
            </span>

            <span
              className={classNames(
                currentTab === "usage"
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
              )}
              onClick={() => setCurrentTab("usage")}
            >
              Usage
            </span>
          </nav>
        </div>
      </div>
      {currentTab === "sales" ? <Sales /> : <Usage />}
    </div>
  );
};

export default SalesAndUsage;
