import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BASE_URL } from "Constant/Constant";
import { useAuth } from "../../Auth";
import { Link } from "react-router-dom";
import SpinningLoader from "components/SpinningLoader/SpinningLoader";
import { useSelector, useDispatch } from "react-redux";
import { loginSliceSelector, loginUser } from "Redux/Features/Auth/Auth";


export default function SignIn() {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const dispatch = useDispatch<any>();

  const auth = useAuth();

  const { loginError, loginLoader, loginData } =
    useSelector(loginSliceSelector);


  const [signInLoader, setSignInLoader] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const navigate = useNavigate();

  const handleLogin = (data: any) => {

    dispatch(loginUser(data.email, data.password,() => {
      navigate("/admin");
      // Navigation logic to be executed after successful login
      // e.g., navigate to the next screen using your preferred method
    }));

    // setSignInLoader(true);
    // setBackendError(false);

    // const staticsBody = {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },

    //   body: JSON.stringify({
    //     request: {
    //       method: "loginUser",
    //       data: {
    //         user: {
    //           email: data.email,
    //           password: data.password,
    //         },
    //       },
    //     },
    //   }),
    // };

    // const staticsResult = fetch(`${BASE_URL}users`, staticsBody);
    // staticsResult
    //   .then((response: any) => response.json(response))
    //   .then((data: any) => {
    //     if (!data.response.data.error) {
    //       localStorage.setItem("auth", JSON.stringify(data.response.data.user));
    //       setSignInLoader(false);
    //       auth.login("Hassan");
    //       navigate("/admin");
    //     }
    //     setBackendError(true);
    //     setSignInLoader(false);
    //   })
    //   .catch((error) => console.log("error", error));

  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        {/* email */}
        <form onSubmit={handleSubmit(handleLogin)}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            nameRegister="email"
            validationSchema={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            register={register}
            errors={errors}
            state={
              Object.keys(errors).length === 0
                ? ""
                : errors && errors.email
                  ? "error"
                  : "success"
            }
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            nameRegister="password"
            validationSchema={{
              required: "Password is required",
              minLength: {
                value: 3,
                message: "Password must have at least 3 characters",
              },
            }}
            register={register}
            errors={errors}
            state={
              Object.keys(errors).length === 0
                ? ""
                : errors && errors.password
                  ? "error"
                  : "success"
            }
          />

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
          </div>

          {loginError ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <small className=" text-sm font-medium text-red-700 dark:text-red-700">
                  Email and password is incorrect
                </small>
              </div>
            </div>
          ) : (
            ""
          )}

          {loginLoader ? (
            <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-500 dark:bg-brand-500 dark:text-white dark:hover:bg-brand-600 dark:active:bg-brand-200">
              <SpinningLoader />
            </button>
          ) : (
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-500 dark:bg-brand-500 dark:text-white dark:hover:bg-brand-600 dark:active:bg-brand-200"
              type="submit"
            >
              Sign In
            </button>
          )}
          <div className="mt-2 flex items-end justify-end px-2">
            <div className="flex items-center justify-between">

              <Link to="privacy-policy">
                <p className="ml-2 cursor-pointer text-sm font-medium text-blue-700 underline dark:text-blue-500">
                  visit our privacy policy
                </p>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

//  <div className="mt-4">
//           <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
//             Not registered yet?
//           </span>
//           <a
//             href=" "
//             className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
//           >
//             Create an account
//           </a>
//         </div>

// <a
//          className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
//        href=" "
//    >
//    Forgot Password?
//</a>
