import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useForm, SubmitHandler } from "react-hook-form"
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { BASE_URL_TWO, LIQUID, PRODUCT, allMachinesLists } from 'Constant/Constant'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import { CreateRolesMachines, UpdateRolesProps } from '../CreateRolesInterfaces'
import axios from 'axios'
import { toast } from 'react-toastify'
import MultiSelectOil from './MultiSelectOil'

type Inputs = {
    email: string
    firstName: string
    lastName: string
    password: string
    userRole: string
    machines: string
}

export default function UpdateModal(props: UpdateRolesProps) {
    const { open, setOpen, updateUser, fetchApiData } = props;

    const [loading, setLoading] = useState(false);

    const [machineType, setMachineType] = useState("");
    const [selectedMachines, setSelectedMachines] = useState([]);

    const [showPassword, setShowPassword] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue
    } = useForm<Inputs>();

    let checkUserRole = watch("userRole");

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        let checkUserRole = watch("userRole");
        setLoading(true);
        if (checkUserRole === "company") {
            if (selectedMachines) {
                axios.put(`${BASE_URL_TWO}updateBySuperAdmin`, {
                    id: updateUser.id,
                    email: data.email,
                    userRole: updateUser.user_role,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: data.password,
                    companyCode: 0,
                    machine_code: selectedMachines.map((e) => { return { machine_code: e.machineCode } }),
                    roleCode: data.userRole === "ops" ? "2" : data.userRole === "admin" ? "1" : "3",
                    machine_type: machineType
                }).then((res) => {
                    setLoading(false);
                    if (res.data.statusCode === "409") {
                        toast.error(res.data.message);
                    } else {
                        toast.success(res.data.message);
                        fetchApiData();
                        setOpen(!open);
                    }
                }).catch(() => {
                    setLoading(false);
                    toast.error("Something went wrong");
                })
            }
            return
        } else {
            axios.put(`${BASE_URL_TWO}updateBySuperAdmin`, {
                id: updateUser.id,
                email: data.email,
                userRole: data.userRole,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password,
                companyCode: 0,
                roleCode: data.userRole === "ops" ? 2 : data.userRole === "admin" ? 1 : 3
            }).then((res) => {
                setLoading(false);
                if (res.data.statusCode === "409") {
                    toast.error(res.data.message);
                } else {
                    toast.success(res.data.message);
                    fetchApiData();
                    setOpen(!open);
                }
            }).catch(() => {
                setLoading(false);
                toast.error("Something went wrong");
            })
        }
    };

    useEffect(() => {
        setValue("firstName", updateUser.first_name);
        setValue("lastName", updateUser.last_name);
        setValue("email", updateUser.email);
        setValue("password", updateUser.password);
        setValue("userRole", updateUser.user_role);

        if (updateUser.user_role === "company") {
            setMachineType(updateUser.machine_type);
        }

        const machineSelected = updateUser.machines.map((machine: CreateRolesMachines) => {
            const machinesUpdatedOne = allMachinesLists.find((machineList) => machineList.machineCode === machine.machine_code);
            return { label: machinesUpdatedOne.label, value: machinesUpdatedOne.value, type: machinesUpdatedOne.type, machineName: machinesUpdatedOne.machineName, machineCode: machinesUpdatedOne.machineCode }
        });
        setSelectedMachines(machineSelected);

    }, [updateUser]);

    

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-full overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <form onSubmit={handleSubmit(onSubmit)} className=''>
                                    <div className='mt-5 grid grid-cols-2 gap-4'>
                                        <div className="p-1">
                                            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                                                First name
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"

                                                    className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.firstName ? "text-red-900" : "text-black"} ring-1 ring-inset ${errors.firstName ? "ring-red-300" : "ring-black"} ${errors.firstName ? "placeholder:text-red-300" : "placeholder:text-black"}  focus:ring-2 focus:ring-inset ${errors.firstName ? "focus:ring-red-500" : "focus:ring-black"} sm:text-sm sm:leading-6`}
                                                    placeholder="John"
                                                    aria-invalid="true"
                                                    aria-describedby="firstName-error"
                                                    {...register("firstName", {
                                                        required: true,

                                                    })}
                                                />
                                                {errors.firstName &&
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                errors.firstName &&
                                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                                    Please enter fields
                                                </p>
                                            }
                                        </div>
                                        <div className="p-1">
                                            <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                                                Last name
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.lastName ? "text-red-900" : "text-black"} ring-1 ring-inset ${errors.lastName ? "ring-red-300" : "ring-black"} ${errors.lastName ? "placeholder:text-red-300" : "placeholder:text-black"}  focus:ring-2 focus:ring-inset ${errors.lastName ? "focus:ring-red-500" : "focus:ring-black"} sm:text-sm sm:leading-6`}
                                                    placeholder="Doe"
                                                    aria-invalid="true"
                                                    aria-describedby="lastName-error"
                                                    {...register("lastName", {
                                                        required: true
                                                    })}
                                                />
                                                {errors.lastName &&
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                errors.lastName &&
                                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                                    Please enter fields
                                                </p>
                                            }

                                        </div>

                                        <div className="p-1">
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Email
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.email ? "text-red-900" : "text-black"} ring-1 ring-inset ${errors.email ? "ring-red-300" : "ring-black"} ${errors.email ? "placeholder:text-red-300" : "placeholder:text-black"}  focus:ring-2 focus:ring-inset ${errors.email ? "placeholder:text-red-300" : "placeholder:text-black"} ${errors.email ? "focus:ring-red-500" : "focus:ring-black"} sm:text-sm sm:leading-6`}
                                                    placeholder="you@example.com"
                                                    aria-invalid="true"
                                                    aria-describedby="email-error"
                                                    {...register("email", {
                                                        required: true, pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                                {errors.email &&
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                errors.email &&
                                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                                    Not a valid email address.
                                                </p>
                                            }
                                        </div>

                                        <div className="p-1">
                                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                Password
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    type={showPassword ? "text" : "password"} // Show or hide password based on state
                                                    name="password"
                                                    id="password"
                                                    className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.password ? "text-red-900" : "text-black"} ring-1 ring-inset ${errors.password ? "ring-red-300" : "ring-black"} ${errors.password ? "placeholder:text-red-300" : "placeholder:text-black"}  focus:ring-2 focus:ring-inset ${errors.password ? "focus:ring-red-500" : "focus:ring-black"} sm:text-sm sm:leading-6`}
                                                    placeholder="*******"
                                                    aria-invalid="true"
                                                    aria-describedby="password-error"
                                                    {...register("password", {
                                                        required: true,
                                                    })}
                                                />
                                                {errors.password &&
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                }
                                                {/* Checkbox to toggle password visibility */}
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                                                        onChange={() => setShowPassword(!showPassword)} // Toggle state when checkbox is clicked
                                                    />
                                                </div>
                                            </div>
                                            {
                                                errors.password &&
                                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                                    Please enter a password
                                                </p>
                                            }
                                        </div>

                                        <div className="p-1">
                                            <label htmlFor="userRole" className="block text-sm font-medium leading-6 text-gray-900">
                                                User role
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <select
                                                    name="userRole"
                                                    id="userRole"
                                                    disabled
                                                    className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.email ? "text-red-900" : "text-black"} ring-1 ring-inset ${errors.email ? "ring-red-300" : "ring-black"} ${errors.email ? "placeholder:text-red-300" : "placeholder:text-black"}  focus:ring-2 focus:ring-inset ${errors.email ? "placeholder:text-red-300" : "placeholder:text-black"} ${errors.email ? "focus:ring-red-500" : "focus:ring-black"} sm:text-sm sm:leading-6`}
                                                    {...register("userRole", {
                                                        required: true
                                                    })}
                                                >
                                                    <option value={"ops"}>Ops/ Distrubutions</option>
                                                    <option value={"admin"}>Admin</option>
                                                    <option value={"company"}>Company</option>
                                                </select>
                                                {errors.email &&
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                errors.email &&
                                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                                    Not a valid email address.
                                                </p>
                                            }
                                            <p className='text-xs mt-1 text-yellow-800'>You can't update user role</p>

                                        </div>

                                        {
                                            checkUserRole === "company" ? (
                                                <div>
                                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                                        Select Machine Type
                                                    </label>
                                                    <select
                                                        id="machine_type"
                                                        name="machine_type"
                                                        className="mt-3 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={machineType}
                                                        onChange={(e: any) => setMachineType(e.target.value)}
                                                    >
                                                        <option>Select machine type</option>
                                                        <option value={LIQUID}>Oil</option>
                                                        <option value={PRODUCT}>Butterfly</option>
                                                    </select>
                                                </div>
                                            ) : null
                                        }
                                    </div>

                                    {
                                        machineType === LIQUID && checkUserRole === "company" ?
                                            <div className="p-1">
                                                <label htmlFor="userRole" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Machines
                                                </label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    {
                                                        updateUser.user_role === "company" && updateUser.machines.length > 0 ?
                                                            <MultiSelectOil data={allMachinesLists.filter((e: any) => e.type === LIQUID)} setSelectedMachines={setSelectedMachines} machineUpdate={selectedMachines} />
                                                            : null
                                                    }
                                                    {errors.email &&
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    errors.email &&
                                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                                        Not a valid email address.
                                                    </p>
                                                }
                                            </div> :
                                            machineType === PRODUCT && checkUserRole === "company" ?
                                                <div className="p-1">
                                                    <label htmlFor="userRole" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Machines
                                                    </label>
                                                    <div className="relative mt-2 rounded-md shadow-sm">
                                                        {
                                                            updateUser.user_role === "company" && updateUser.machines.length > 0 ?
                                                                <MultiSelect data={allMachinesLists.filter((e: any) => e.type === PRODUCT)} setSelectedMachines={setSelectedMachines} machineUpdate={selectedMachines} />
                                                                : null
                                                        }
                                                        {errors.email &&
                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        errors.email &&
                                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                                            Not a valid email address.
                                                        </p>
                                                    }
                                                </div> : null

                                    }

                                    <div className='mt-4 flex justify-end pb-20'>
                                        {
                                            loading ?
                                                <button
                                                    className="block cursor-pointer rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                                >Loading...</button> :
                                                <input
                                                    className="block cursor-pointer rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                                    type="submit" />
                                        }
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
