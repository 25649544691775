import React from 'react'

const RealTimeStocks = (props: { realTimeData: any }) => {
  const { realTimeData } = props
  return (
    <div className="bg-white dark:!bg-gray-900 py-10">
      <main className="" aria-labelledby="order-history-heading">
        <div className="w-full">

        </div>
        <div className="mt-5">
          {
            realTimeData.map((e: any) => {
              return (
                <div key={e.id} className=''>
                  <h1
                    id="order-history-heading"
                    className="text-center text-3xl font-bold tracking-tight text-gray-900"
                  >
                    {e.name} - Row {e.row_num}
                  </h1>
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Last Batch Refill
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Stocks Out
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Current stock
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {/* {e.refillData.map((stock: any, i: any) => ( */}
                                <tr>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {e.lastBatchRefill}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{e.stocksMinus}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{e.lastBatchRefill - e.stocksMinus}</td>
                                </tr>
                                {/* ))} */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </main>
    </div>
  )
}

export default RealTimeStocks