import { useState } from "react";
import PieChart from "components/charts/PieChart";
import Card from "components/card";
import Datepicker from "react-tailwindcss-datepicker";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { addCommasToNumber, decryptObject, pieCsvHead } from "Constant/Constant";
import "./css/machine.css"
import { CSVLink } from "react-csv";
import pendingImage from "../../../../assets/img/work_in_progress.jpg"


const PieChartCard = (props: any) => {
  const {
    value,
    handleValueChange,
    pieRevenue,
    pieVolume,
    pieTransaction,
    pieChartLoader,
    pieTotal,
    pieChartStats
  } = props;

  const [pieState, setPieState] = useState("quantity");

  const revenueLabels = pieRevenue && Object.keys(pieRevenue);
  const revenueValues = pieRevenue && Object.values(pieRevenue);

  const volumeLabels = pieVolume && Object.keys(pieVolume);
  const volumeValues = pieVolume && Object.values(pieVolume);

  const transactionLabels = pieTransaction && Object.keys(pieTransaction);
  const transactionValues = pieTransaction && Object.values(pieTransaction);

  const pieChartData =
    pieState === "quantity"
      ? volumeValues
      : pieState === "transaction"
        ? transactionValues
        : revenueValues;

  const pieChartOptions = {
    labels:
      pieState === "quantity"
        ? volumeLabels
        : pieState === "transaction"
          ? transactionLabels
          : revenueLabels,
    colors: ["#FF6633", "#FF33FF", "#00B3E6", "#E6B333", "#3366E6", "#999966"],
    chart: {
      width: "10px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    // hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [
        "#FF6633",
        "#FF33FF",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
      ],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        // fontFamily: undefined,
        backgroundColor: "#000000",
      },
    },
  };

  let revenueCsv = Object.entries(pieTransaction).map(([product, amount]) => ({ revenueProduct: product, revenueAmount: amount }));

  let pieVolumeCsv = Object.entries(pieVolume).map(([product, amount]) => ({ volumeProduct: product, volumeAmount: amount }));

  let pieTransactionCsv = Object.entries(pieTransaction).map(([product, amount]) => ({ transactionProduct: product, transactionAmount: amount }));

  revenueCsv = Array.prototype.concat(revenueCsv, pieVolumeCsv, pieTransactionCsv);

  const some = revenueCsv.map((e: any) => {
    let some1 = {};
    let some2 = {};
    pieVolumeCsv.forEach((e) => some1 = e)
    pieTransactionCsv.forEach((e) => some2 = e)
    return {
      ...e, ...some1, ...some2
    }
  })

  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  return (
    <Card extra="rounded-[20px] p-3">


      <div className="flex justify-start">
        <CSVLink data={some} headers={pieCsvHead} className="block w-32 rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
          Export as csv
        </CSVLink>
      </div>
      {!pieChartLoader ? (
        <>
          <div className="flex flex-row items-center justify-between px-3 pt-2">
            <div className=" flex items-center justify-center">
              <select
                className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white"
                value={pieState}
                onChange={(e) => setPieState(e.target.value)}
              >
                <option value="quantity">Quantity</option>
                <option value="transaction">Transaction</option>
                <option value="revenue">Revenue</option>
              </select>
            </div>

            <div className="w-72">
              <Datepicker
                value={value}
                primaryColor={"fuchsia"}
                onChange={handleValueChange}
                showShortcuts={true}
                useRange={false}
              // inputClassName={"dark:!text-black dark:!bg-gray-900 "}
              />
            </div>
          </div>

          <div className="mb-auto mt-20 flex h-[220px] w-full items-center justify-center">
            <PieChart chartOptions={pieChartOptions} chartData={pieChartData} />
          </div>
          <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-brand-500" />
                <p className="ml-1 text-sm font-normal text-gray-600">
                  Total Revenue
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
                {addCommasToNumber(Math.floor(pieTotal.totalRevenue))}
              </p>
            </div>

            <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
                <p className="ml-1 text-sm font-normal text-gray-600">
                  Total Transaction
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                {addCommasToNumber(pieTotal.totalTransaction)}
              </p>
            </div>

            <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
                <p className="ml-1 text-sm font-normal text-gray-600">
                  Total Volume
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                {addCommasToNumber(pieTotal.totalVolume)}
              </p>
            </div>
          </div>
        </> 
      ) : (
        <SkeletonLoader />
      )}

    </Card>
  );
};

export default PieChartCard;
